import KeywordService from '@/services/KeywordService'

export default {
  namespaced: true,
  state: {
    keywords: [],
    keywordToEdit: {},
  },
  mutations: {
    setKeywords(state, keywords) {
      state.keywords = [...keywords]
    },
    addKeyword(state, newKeyword) {
      state.keywords.push(newKeyword)
    },
    setKeywordToEdit(state, keyword) {
      state.keywordToEdit = keyword
    },
  },
  getters: {
    keywords(state) {
      return state.keywords
    },
    keywordToEdit(state) {
      return state.keywordToEdit
    },
  },
  actions: {
    async getKeywords({ commit, rootState }) {
      const { activeCompany } = rootState.company
      const {
        body: { keywords },
      } = await KeywordService.list({ companyId: activeCompany._id })
      commit('setKeywords', keywords)
    },

    async addKeyword({ commit, rootState }, fields) {
      const { activeCompany } = rootState.company
      const {
        body: { keyword },
      } = await KeywordService.create({ companyId: activeCompany._id, fields })
      commit('addKeyword', keyword)
      return keyword
    },

    async updateKeyword({ dispatch }, { keywordId, fields }) {
      if (fields.word) {
        delete fields.word
      }
      const response = await KeywordService.update({ keywordId, fields })
      dispatch('getKeywords')
    },

    async archiveKeyword({ dispatch }, { keywordId, notify }) {
      const response = await KeywordService.archive(keywordId)
      console.log(response)
      if (response.body == 'OK') {
        notify({ text: 'Successfully archived keyword.' })
        dispatch('getKeywords')
      } else {
        notify({ type: 'error', text: 'Error: Could not archive keyword.' })
      }
    },

    async generateMagicLinks({ dispatch, state }, { keywordId, fields }) {
      await KeywordService.generateLinks({ keywordId, fields })
      await dispatch('getKeywords')
      return state.keywords.find(({ _id }) => _id === keywordId)
    },
  },
}
