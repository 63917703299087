<template>
  <form class="mb-4">
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label class="mb-1">Require email addresses</label>
          <small class="form-text text-muted"
            >This will require customers to enter their email address. It will also add an email
            field to the iPad Kiosk</small
          >
          <div class="custom-control custom-checkbox-toggle">
            <input
              class="custom-control-input"
              id="requireEmailsToggle"
              type="checkbox"
              v-model="activeLocation.requireEmails"
            />
            <label class="custom-control-label" for="requireEmailsToggle"></label>
          </div>
        </div>
        <div class="form-group">
          <!-- Internal Name maps to friendlyName in the DB -->
          <label class="mb-1">Internal Name</label>
          <small class="form-text text-muted">
            This name will appear in all internal dashboards and reports. If not set, the location's
            city will be used.
          </small>
          <!-- Input -->
          <input
            v-model="activeLocation.friendlyName"
            class="form-control"
            type="text"
            :placeholder="activeLocation.city"
          />
        </div>
        <div class="form-group">
          <button @click="update" type="button" class="btn btn-primary">Update</button>
        </div>

        <div class="form-group">
          <label class="mb-1">Display Name</label>
          <small class="form-text text-muted">
            This name will be used anytime that the location is displayed to customers. If not set,
            the internal name will be used.
          </small>
          <!-- Input -->
          <input
            v-model="activeLocation.displayName"
            class="form-control"
            type="text"
            :placeholder="activeLocation.city"
            @input="(e) => (activeLocation.city = sanitizeInput(e.target.value))"
          />
        </div>
        <div class="form-group">
          <button @click="update" type="button" class="btn btn-primary">Update</button>
        </div>

        <!-- Hours of operation -->
        <div class="form-group">
          <label class="mb-1">Hours of operation</label>
          <small class="form-text text-muted">
            This text will be sent in an SMS message if activated as an IVR option. Included in the
            SMS will be the following message: "Holiday hours may differ. Text back with questions."
          </small>
          <textarea
            v-model="activeLocation.hoursOfOperation"
            class="form-control"
            rows="4"
            @input="sanitizeHoursOfOperation"
          ></textarea>
        </div>
        <div class="form-group">
          <button @click="update" type="button" class="btn btn-primary">Update</button>
        </div>
        <!-- physical address -->
        <div class="form-group">
          <label class="mb-1">Physical Address</label>
          <small class="form-text text-muted"> This is the location's physical address</small>
          <input
            v-model="activeLocation.address"
            class="form-control"
            type="text"
            :placeholder="activeLocation.address"
          />
        </div>
        <div class="form-group">
          <button
            :disabled="isUpdating"
            @click.prevent="updatePhysicalAddress"
            type="button"
            class="btn btn-primary d-flex align-items-center"
          >
            <span v-if="isUpdating" class="loader loader-sm loader-white mr-2"></span>
            {{ isUpdating ? 'Updating...' : 'Update' }}
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import LocationSettingsBase from '../LocationSettingsBase'
import LocationService from '@/services/LocationService'
import { createNamespacedHelpers } from 'vuex'
import { sanitizeInput } from '@/lib/validation'

const LocationModule = createNamespacedHelpers('location')

export default {
  name: 'LocationSettingsBasic',
  extends: LocationSettingsBase,
  data: () => ({
    isUpdating: false,
  }),
  computed: {},
  watch: {
    'activeLocation.friendlyName': {
      immediate: true,
      handler() {
        this.activeLocation.friendlyName = this.activeLocation?.friendlyName?.replace(/’/g, "'")
      },
    },
  },
  methods: {
    ...LocationModule.mapActions(['updateAddress']),
    sanitizeHoursOfOperation(e) {
      this.activeLocation.hoursOfOperation = sanitizeInput(e.target.value)
    },
    async update() {
      const response = await LocationService.update({
        locationId: this.activeLocation._id,
        fields: {
          requireEmails: this.activeLocation.requireEmails,
          friendlyName: this.activeLocation.friendlyName,
          displayName: this.activeLocation.displayName,
          hoursOfOperation: this.activeLocation.hoursOfOperation,
        },
      })

      const { location } = response.body
      this.setActiveLocation(location)

      this.$notify({
        title: 'Location updated',
        text: 'Your settings were saved.',
      })
    },
    async updatePhysicalAddress() {
      if (this.isUpdating) return
      this.isUpdating = true
      try {
        const payload = {
          locationId: this.activeLocation._id,
          address: this.activeLocation.address,
        }
        await this.updateAddress(payload)
        this.$notify({
          title: 'Updated!',
          text: 'Location address updated successfully',
        })
      } catch (error) {
        this.$notify({
          type: 'error',
          text: 'Something went wrong while updating the address. Please try again.',
        })
        console.error(error)
      }
      this.isUpdating = false
    },
  },
}
</script>

<style scoped lang="scss"></style>
