<template>
  <div class="card mr-3 mb-4" style="height: 320px">
    <div class="px-4 pt-4 text-center">
      <div class="">
        <div class="d-flex justify-content-center">
          <h4 class="text-muted" style="font-size: 14px">Mentions By Daypart</h4>
          <i
            class="fas fa-info-circle text-muted ml-2 fa-sm"
            v-b-popover:filters.hover.bottom="disclaimer"
          ></i>
        </div>

        <div class="d-flex justify-content-end">
          <ul class="nav nav-tabs nav-tabs-sm">
            <li class="nav-item">
              <button
                type="button"
                class="btn-link nav-link py-1"
                :class="{ active: !selectedDay }"
                @click="setSelectedDay()"
              >
                Overall
              </button>
            </li>
            <li class="nav-item">
              <div class="btn-group drop-left" role="group">
                <button
                  id="btnGroupDrop1"
                  type="button"
                  class="btn-link dropdown-toggle nav-link py-1"
                  :class="{ active: selectedDay }"
                  data-toggle="dropdown"
                >
                  By Day
                </button>
                <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
                  <span
                    class="dropdown-item"
                    v-for="(value, key, index) in dayMap"
                    :key="index"
                    @click="setSelectedDay(value)"
                  >
                    {{ key }}
                  </span>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="py-3 px-4" style="height: 240px">
      <apexchart
        type="bar"
        height="100%"
        :series="selectMentionsByDaypart(selectedDay)"
        :options="chartOptions"
      />
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const InsightsModule = createNamespacedHelpers('insights')

export default {
  name: 'MentionsByDaypart',
  props: { selectedDay: { type: Number } },
  data: () => ({
    dayMap: { Sunday: 0, Monday: 1, Tuesday: 2, Wednesday: 3, Thursday: 4, Friday: 5, Saturday: 6 },
    disclaimer:
      'This chart only includes data for Ovation surveys because of inaccuracies relating to the timing of online reviews.',
  }),
  methods: {
    setSelectedDay(day) {
      this.$emit('selectedDay', day)
    },
  },
  computed: {
    ...InsightsModule.mapGetters(['selectMentionsByDaypart']),
    chartOptions() {
      return {
        chart: {
          height: 240,
          type: 'bar',
          toolbar: { show: false },
          zoom: { enabled: false },
        },
        colors: ['#FC6D87', '#33D691'],
        plotOptions: {
          bar: {
            borderRadius: 12,
            columnWidth: '30%',
          },
        },
        grid: { show: true, borderColor: '#D2DDEC', strokeDashArray: 2 },
        noData: { text: 'No data to show' },
        dataLabels: { enabled: false },
        xaxis: {
          categories: ['Morning', 'Afternoon', 'Evening'],
          tooltip: { enabled: false },
          tickPlacement: 'on',
        },
        yaxis: {
          labels: { show: true },
          axisBorder: { show: true },
          max: 100,
          min: 0,
          tickAmount: 4,
          labels: { formatter: (val) => parseInt(val) },
        },
        tooltip: {
          enabled: true,
          y: {
            formatter: (value) => `${value.toFixed(0)}%`,
          },
        },
        stroke: { width: 0.5 },
        legend: { show: false },
      }
    },
  },
}
</script>
