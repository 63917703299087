import { cloneDeep } from 'lodash-es'
import LocationService from '@/services/LocationService'

export default {
  namespaced: true,
  state: {
    searchQuery: '',
    isSearching: false,
    activeSettingsTab: undefined,
    activeLocation: undefined,
    locationBeingAdded: undefined,
    userLocations: [],
    userActiveCompanyLocations: [],
    dashboardLocations: [],
    insightsLocations: [],
  },
  getters: {
    selectDashboardLocations: (state, getters) => (ids) => {
      let locations = ids.length
        ? (state.dashboardLocations.length
            ? state.dashboardLocations
            : getters.selectActiveLocations
          ).filter((l) => ids.includes(l.company))
        : getters.selectActiveLocations

      return locations.filter((l) => !l.disabled)
    },
    userLocations: (state) => state.userLocations,
    userActiveCompanyLocations: (state) => state.userActiveCompanyLocations,
    selectUserActiveCompanyLocations: (state) => state.userActiveCompanyLocations,
    activeLocations(state, _, rootState) {
      const { activeCompany } = rootState.company
      return state.userLocations.filter((l) => l.company === activeCompany._id)
    },
    userActiveLocationNames(state) {
      return state.userActiveCompanyLocations.map((location) => {
        return {
          _id: location._id,
          name: location.name,
          friendlyName: location.friendlyName,
          city: location.city,
        }
      })
    },
    selectLocationName: (state) => (id) => {
      const location = state.userActiveCompanyLocations.find((el) => el._id === id)
      return location?.friendlyName || location?.city
    },
    selectFilteredLocations: (state) => (ids) => {
      return state.userActiveCompanyLocations.filter((loc) => ids.includes(loc._id))
    },
    selectActiveLocations(state) {
      return state.userActiveCompanyLocations.filter((l) => !l.disabled)
    },
    selectDisabledLocations(state) {
      return state.userActiveCompanyLocations.filter((l) => l.disabled)
    },
    selectIsActive: (state) => (id) => {
      return !!!state.userActiveCompanyLocations.find((el) => el._id === id)?.disabled
    },
    selectLocation: (state) => (locationId) =>
      state.userActiveCompanyLocations.find((l) => l._id === locationId),
    selectAllLocationReviewNetworks: (state) => {
      let networkSet = new Set()
      state.userActiveCompanyLocations.forEach((l) => {
        if (l.subscriptions) {
          l.subscriptions.forEach((sub) => {
            if (sub.type === 'review-subscription') {
              networkSet.add(sub.network)
            }
          })
        }
      })
      return Array.from(networkSet)
    },
    selectLocationReviewSubscriptionsByNetwork: (state) => (network, locationIds) => {
      let reviewSubscriptionIds = []
      state.userActiveCompanyLocations.forEach((l) => {
        if (l.subscriptions) {
          l.subscriptions.forEach((sub) => {
            if (
              sub.type === 'review-subscription' &&
              sub.network === network &&
              locationIds?.includes(l._id.toString())
            ) {
              reviewSubscriptionIds.push(sub.reviewSubscription._id)
            }
          })
        }
      })
      return reviewSubscriptionIds
    },
    selectActiveLocation: (state) => state.activeLocation,
    selectInsightsLocations: (state) => state.insightsLocations.filter((l) => !l.disabled),
    selectInsightsLocationsByCompanies: (state) => (companyIds) =>
      state.insightsLocations.filter((l) => companyIds.includes(l.company)),
    selectLocationsByCompanies: (state, getters) => (companyIds) =>
      companyIds?.length
        ? getters.selectActiveLocations.filter((l) => companyIds.includes(l.company))
        : getters.selectActiveLocations,
  },
  mutations: {
    setSearchQuery(state, query) {
      state.searchQuery = query
    },
    setIsSearching(state, isSearching) {
      state.isSearching = isSearching
    },
    resetLocationSearch(state) {
      state.searchQuery = ''
      state.isSearching = false
    },
    setActiveSettingsTab(state, tab) {
      state.activeSettingsTab = tab
    },
    setActiveLocation(state, location) {
      state.activeLocation = cloneDeep(location)
    },
    SET_LOCATION_BEING_ADDED(state, location) {
      state.locationBeingAdded = cloneDeep(location)
    },
    setLocationPlatforms(state, platforms) {
      state.activeLocation.platforms = platforms
    },
    setLocationReviewSubscriptionsOrder(state, subscriptions) {
      state.activeLocation.subscriptions = subscriptions.map((s, i) => {
        s.displayOrder = i
        return s
      })
    },
    setUserLocations(state, locations) {
      state.userLocations = [...locations]
    },
    setUserActiveCompanyLocations(state, locations) {
      state.userActiveCompanyLocations = locations
    },
    SET_DASHBOARD_LOCATIONS(state, locations) {
      state.dashboardLocations = locations
    },
    ADD_GOOGLE_BUSINESS_PROFILE_ID(
      state,
      { locationId, reviewSubscription, googleBusinessProfileId }
    ) {
      const location =
        state.activeLocation?._id === locationId
          ? state.activeLocation
          : state.userActiveCompanyLocations.find((l) => l._id === locationId)
      if (location) {
        const reviewSub = location.reviewSubscriptions.find((rs) => rs._id === reviewSubscription)
        if (reviewSub) {
          reviewSub['googleBusinessProfileId'] = googleBusinessProfileId
          if (state.activeLocation?._id === locationId) {
            state.activeLocation = { ...state.activeLocation }
          }
        }
      }
    },
    SET_SUBSCRIPTION_HIDDEN(state, { index, status }) {
      state.activeLocation.subscriptions[index]['hidden'] = status
    },
    SET_INSIGHTS_LOCATIONS(state, locations) {
      state.insightsLocations = locations
    },
  },
  actions: {
    setLocationBeingAdded({ commit }, location) {
      commit('SET_LOCATION_BEING_ADDED', location)
    },

    async addGoogleBusinessProfileId(
      { commit },
      { locationId, reviewSubscription, googleBusinessProfileId }
    ) {
      commit('ADD_GOOGLE_BUSINESS_PROFILE_ID', {
        locationId,
        reviewSubscription,
        googleBusinessProfileId,
      })
    },

    async getUserLocations({ rootState, commit }) {
      const { companies } = rootState.company

      const responses = await Promise.all(
        companies.map((c) =>
          LocationService.getForUser({
            companyId: c._id,
          })
        )
      )
      const locations = responses.reduce(
        (previous, current) => previous.concat(current.body.data.locations),
        []
      )

      commit('setUserLocations', locations)
    },

    // this is for when on the dashboard under a specific activeCompany and the company filter is used
    // this is needed because the user locations that are loaded are for the active company and the
    // other company's need their locations loaded as well but separately from the activeCompany locations.
    async fetchDashboardLocations({ commit, rootGetters }, { companyIds }) {
      // if no ids are passed in then its 'all companies'
      companyIds = companyIds.length
        ? companyIds
        : rootGetters['company/companies'].map((c) => c._id)

      const responses = await Promise.all(
        companyIds.map((companyid) =>
          LocationService.getForUser({
            companyId: companyid,
          })
        )
      )
      const locations = responses.reduce(
        (previous, current) => previous.concat(current.body.data.locations),
        []
      )
      commit('SET_DASHBOARD_LOCATIONS', locations)
    },

    async getUserLocationsByActiveCompany({ rootGetters, commit }) {
      const companies =
        rootGetters['company/activeCompany']?._id == 'ALL_COMPANIES'
          ? rootGetters['company/companies']
          : [rootGetters['company/activeCompany']]

      const result = await LocationService.getLocations({
        filters: { companyIds: companies.map((c) => c._id) },
        select:
          'activationCode address addressDetails bandwidth city company devices disabled displayName friendlyName hideFromReports hoursOfOperation name placeId primaryPhoto products requireEmails settings subscriptions timezone totalSurveys',
        populate: [
          {
            path: 'subscriptions.reviewSubscription',
            select:
              'network profileImage name formattedAddress url slug reviewCount globalRating disabled googleBusinessProfileId',
          },
          { path: 'addressDetails' },
        ],
      })
      result.data.data.forEach((location) => {
        location.reviewSubscriptions = location.subscriptions
          ? location.subscriptions
              .map((sub) => ({
                ...sub.reviewSubscription,
                displayOrder: sub.displayOrder,
              }))
              .sort((a, b) => a.displayOrder - b.displayOrder)
          : []
      })
      commit('setUserActiveCompanyLocations', result.data.data)
      commit(
        'SET_INSIGHTS_LOCATIONS',
        result.data.data.filter((l) => companies.find((c) => c._id === l.company)?.insightsEnabled)
      )
    },

    async createLocation({ commit }, payload) {
      const response = await LocationService.create(payload)
      return response
    },

    async addProducts({}, payload) {
      const response = await LocationService.addProducts(payload)
      return response
    },

    async updateLocation(_, { locationId, fields }) {
      await LocationService.update({ locationId, fields })
    },

    async updateSubscriptionHidden({ commit }, { index, status }) {
      commit('SET_SUBSCRIPTION_HIDDEN', { index, status })
    },

    async updateAddress({ commit }, payload) {
      const response = await LocationService.updateAddress(payload)
      return response
    },
  },
}
