<template>
  <div>
    <div class="row">
      <form class="col-12">
        <!-- promo nickname -->
        <div class="form-group mb-4">
          <label for="promoNickname mb-1">What do you want to call this offer internally?</label>
          <small class="form-text text-muted">
            This nickname will not be displayed to your customers.
          </small>
          <input
            type="text"
            id="promoNickname"
            v-model="form.nickname"
            placeholder="Enter nickname"
            class="form-control"
            @input="updateNickname"
            v-b-popover.hover.topleft="
              'This is what you will see as the name of your offer internally.'
            "
            title="Nickname"
          />
        </div>

        <!-- promo title -->
        <div class="form-group mb-4">
          <label for="promoTitle mb-1">What's a good title for your offer?</label>
          <small class="form-text text-muted">
            Customers will see this title when they go to redeem this offer.
          </small>
          <input
            type="text"
            id="promoTitle"
            v-model="form.title"
            placeholder="20% Off Next Visit"
            class="form-control"
            @input="updateTitle"
            v-b-popover.hover.topleft="
              'This is what your customers will see when redeeming this offer.'
            "
            title="Title"
          />
        </div>

        <!-- additional details -->
        <div class="form-group mb-4">
          <label for="promoTitle mb-1">What other details or terms do you want to add?</label>
          <small class="form-text text-muted">
            Customers will see this additional information below the title.
          </small>
          <textarea
            v-model="form.description"
            @keyup="updateDescription"
            @input="sanitizeDescription"
            class="form-control sms text-message-orig"
            id="txt"
            maxlength="1000"
            name="content"
            placeholder="Only one per customer...."
            rows="6"
          ></textarea>
        </div>

        <!-- terms and conditions -->
        <div class="form-group mb-4">
          <label for="termsAndConditions mb-1">
            Would you like to link them to additional terms? (optional)
          </label>
          <small class="form-text text-muted">
            This will add a link to the bottom of your terms above where customers will be
            redirected.
          </small>
          <input
            type="text"
            id="termsAndConditions"
            v-model="form.termsAndConditions"
            placeholder="https://www.example.com/termsandconditions"
            class="form-control"
            @input="updateTermsAndConditions"
          />
          <small class="form-text text-danger" v-if="!isValidURL && this.form.termsAndConditions">
            Please provide a properly formatted URL
          </small>
        </div>

        <!-- display options -->
        <div class="form-group mb-4">
          <label for="promoTitle mb-1">Where would you like your offer to be shown?</label>
          <small class="form-text text-muted"
            >Your offers will appear as options base on where you want it to be shown.</small
          >
          <MultiSelect
            id="offer-type"
            :allow-empty="false"
            :searchable="false"
            :show-labels="false"
            openDirection="below"
            label="name"
            track-by="value"
            preselectFirst
            placeholder="Select type"
            v-model="form.source"
            :options="sourceOptions"
          ></MultiSelect>
        </div>

        <!-- company selection -->
        <div class="form-group mb-4" v-if="relevantCompanies.length > 1">
          <label class="mb-1">Companies</label>
          <small class="form-text text-muted">
            By default, this offer will be available for all companies. If you'd like to limit the
            companies this offer is available to, select those companies below.
          </small>
          <GroupingFilter
            :nonGroupMembers="relevantCompanies"
            :placeholder="'All Companies'"
            nonGroupMemberLabel="Companies"
            memberType="companies"
            @update="companyGroupingUpdate"
            :init="{
              nonGroupMemberIds: this.selectPromo.companies,
              groupIds: null,
              disabledNonGroupMemberIds: null,
            }"
          />
        </div>

        <!-- location selection -->
        <div class="form-group mb-4" v-if="selectActiveCompany._id !== 'ALL_COMPANIES'">
          <label class="mb-1">Locations</label>
          <small class="form-text text-muted">
            By default, this offer will be available for all locations. If you'd only like certain
            locations to use this offer, select those locations below.
          </small>
          <GroupingFilter
            :nonGroupMembers="relevantLocations"
            nonGroupMemberLabel="Locations"
            memberType="locations"
            @update="groupingUpdate"
            :init="{
              nonGroupMemberIds: this.selectPromo.locations,
              groupIds: null,
              disabledNonGroupMemberIds: null,
            }"
            :key="groupRefreshKey"
          />
        </div>

        <!-- promo codes -->
        <CreatePromoCodeView />
        <hr class="mt-4 mb-5" />
        <div class="form-group mb-4">
          <PromoCreationExpiration id="promoExpirationPicker" />
        </div>
        <hr class="mt-4 mb-5" />
        <div>
          <a
            @click="savePromo"
            href="javascript:;"
            class="btn btn-block btn-lg btn-primary"
            :disabled="isLoading"
            :class="{ 'is-loading': isLoading }"
          >
            {{ selectPromo._id ? 'Edit' : 'Save' }}
          </a>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import CustomerLocationFilter from '@/components/Modules/Customer/CustomerFilters/CustomerLocationFilter'
import PromoCreationExpiration from './PromoCreationExpiration'
import CreatePromoCodeView from './CreatePromoCodeView'
import MultiSelect from '@/components/Common/MultiSelect'
import GroupingFilter from '@/components/Modules/Filters/GroupingFilter'
import { createNamespacedHelpers } from 'vuex'
import { sanitizeInput } from '@/lib/validation'

const PromoModuleV2 = createNamespacedHelpers('promoV2')
const CompanyModule = createNamespacedHelpers('company')
const LocationModule = createNamespacedHelpers('location')
const GroupModule = createNamespacedHelpers('group')

export default {
  name: 'PromoCreationForm',
  props: ['modal'],
  components: {
    MultiSelect,
    CreatePromoCodeView,
    CustomerLocationFilter,
    PromoCreationExpiration,
    GroupingFilter,
  },
  data: () => ({
    isLoading: false,
    form: {
      title: '',
      description: '',
      promoCode: '',
      nickname: '',
      termsAndConditions: '',
    },
    sourceOptions: [
      { name: 'Both campaign page and winback conversation', value: 'all' },
      { name: 'On winback conversation only', value: 'winback' },
      { name: 'On campaign page only', value: 'campaign' },
    ],
    isValidURL: true,
    filteredGroups: null,
    filteredGroupMembers: null,
    filteredLocations: null,
    filteredDisabledLocations: null,
    filteredCompanies: [],
  }),
  computed: {
    ...PromoModuleV2.mapGetters(['selectPromo']),
    ...CompanyModule.mapGetters(['selectActiveCompany', 'selectRelevantCompanies']),
    ...LocationModule.mapGetters(['selectLocationsByCompanies', 'selectLocation']),
    ...GroupModule.mapGetters(['selectGroups']),
    relevantCompanies() {
      return this.selectRelevantCompanies.filter((c) => c.status !== 'cancelled')
    },
    relevantCompanyIds() {
      return this.relevantCompanies.map((c) => c._id)
    },
    relevantLocations() {
      const companyIds = this.filteredCompanies?.length
        ? this.filteredCompanies
        : this.selectRelevantCompanies.map((c) => c._id)
      return this.selectLocationsByCompanies(companyIds) || []
    },
    groupRefreshKey() {
      return (
        (this.selectActiveCompany?._id || 'NO_COMPANY') +
        (this.filteredCompanies?.join('') || '') +
        (this.relevantLocations.length || 0) +
        this.selectGroups.map((g) => g._id).join('')
      )
    },
  },
  mounted() {
    this.loadRelevantGroups()
    if (this.selectPromo._id) {
      this.form.nickname = sanitizeInput(this.selectPromo.nickname)
      this.form.title = sanitizeInput(this.selectPromo.title)
      this.form.description = sanitizeInput(this.selectPromo.description)
      this.form.termsAndConditions = sanitizeInput(this.selectPromo.termsAndConditions)
      this.filteredCompanies = this.selectPromo.companies
    }
    const source = this.selectPromo.source
      ? this.sourceOptions.find((s) => s.value === this.selectPromo.source)
      : this.sourceOptions[0]

    this.$set(this.form, 'source', source || this.sourceOptions[0])
  },
  beforeDestroy() {
    this.resetPromo()
  },
  methods: {
    ...GroupModule.mapActions(['selectGroupLocationIds', 'fetchGroups']),
    ...PromoModuleV2.mapActions(['createPromo', 'setPromo', 'resetPromo', 'updatePromo']),
    async loadRelevantGroups() {
      await this.fetchGroups({
        memberType: 'location',
        companyIds: this.filteredCompanies.length
          ? this.filteredCompanies
          : this.relevantCompanies.map((c) => c._id),
      })
    },
    companyGroupingUpdate(val) {
      this.filteredCompanies = val?.nonGroupMemberIds || []
      this.setPromo({
        ...this.selectPromo,
        companies: this.filteredCompanies,
        locations: [],
      })
      this.loadRelevantGroups()
    },
    async groupingUpdate(val) {
      this.filteredGroups = val && val.groupIds && val.groupIds.length ? val.groupIds : null
      this.filteredGroupMembers =
        val && val.memberIds && val.memberIds.length ? val.memberIds : null
      this.filteredLocations =
        val && val.nonGroupMemberIds && val.nonGroupMemberIds.length ? val.nonGroupMemberIds : null
      this.filteredDisabledLocations =
        val && val.disabledNonGroupMemberIds && val.disabledNonGroupMemberIds.length
          ? val.disabledNonGroupMemberIds
          : null
      let locationsToFilter = [
        ...new Set([
          ...(this.filteredLocations || []),
          ...(this.filteredDisabledLocations || []),
          ...(this.filteredGroupMembers || []),
        ]),
      ]

      this.setPromo({
        ...this.selectPromo,
        locations: locationsToFilter.length
          ? locationsToFilter
          : this.filteredGroups?.length
          ? await this.selectGroupLocationIds(this.filteredGroups)
          : [],
      })
    },
    sanitizeDescription(e) {
      this.form.description = sanitizeInput(e.target.value)
    },
    updateTitle(e) {
      this.form.title = sanitizeInput(e.target.value)
      this.setPromo({ ...this.selectPromo, title: this.form.title })
    },
    updateDescription() {
      this.setPromo({ ...this.selectPromo, description: this.form.description })
    },
    updateNickname(e) {
      this.form.nickname = sanitizeInput(e.target.value)
      this.setPromo({ ...this.selectPromo, nickname: this.form.nickname })
    },
    updateTermsAndConditions(e) {
      this.form.termsAndConditions = sanitizeInput(e.target.value)
      this.setPromo({
        ...this.selectPromo,
        termsAndConditions: this.validateURL(this.form.termsAndConditions)
          ? this.form.termsAndConditions
          : null,
      })
    },
    async savePromo() {
      if (this.form.title.length < 3) {
        this.$notify({
          title: 'Failed to create offer.',
          text: 'Please give your offer a title.',
          type: 'error',
        })
        return
      }
      this.isLoading = true

      const promo = { ...this.selectPromo }
      promo.source = this.form.source.value === 'all' ? '' : this.form.source.value

      try {
        // create/update the promo
        const response = promo._id
          ? await this.updatePromo({ promoId: promo._id, promo: { ...promo } })
          : await this.createPromo({ promo: { ...promo } })

        this.isLoading = false
        this.resetPromo()

        this.$notify({
          title: 'Launched!',
          text: `Your promo is ready to go.`,
        })
        if (!this.modal) {
          this.$router.push({ name: 'promos' })
        } else {
          this.$emit('saved', response.body.data.promo)
        }
      } catch (err) {
        this.isLoading = false
        console.log(err)
        this.$notify({
          title: 'Failed to create offer.',
          text: (err.body && err.body.message) || 'Please contact us for assistance.',
          type: 'error',
        })
      }
    },
    validateURL(url) {
      const results = url.match(
        /^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
      )
      this.isValidURL = results !== null
      return results !== null
    },
  },
}
</script>

<style scoped lang="scss">
.form-group {
  margin-bottom: 0px;
}
#promoTitle {
  text-transform: capitalize;
}
</style>
