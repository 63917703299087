<template>
  <div v-if="initialized">
    <div class="card-header">
      <div class="row">
        <div class="col-12 mb-0">
          <div class="mb-3 clearfix">
            <!-- date -->
            <div class="dropdown d-inline-block mr-4 mb-2 pt--2 filter-container date-filter">
              <h6 class="header-pretitle mt-3">Date Added</h6>
              <DateRangeFilter
                @updateDateSettings="filterByDate"
                :dateSettings="selectFirstFilterDateSettings"
              />
            </div>

            <!-- add company filter -->
            <div
              v-if="selectActiveCompany._id === 'ALL_COMPANIES'"
              class="dropdown d-inline-block mr-4 mb-2 filter-container"
            >
              <h6 class="header-pretitle mt-3">Companies</h6>
              <GroupingFilter
                :nonGroupMembers="relevantCompanies"
                :placeholder="'All Companies'"
                nonGroupMemberLabel="Companies"
                memberType="companies"
                @update="companyGroupingUpdate"
                :init="{
                  nonGroupMemberIds: selectFirstFilterCompanies,
                  groupIds: null,
                  disabledNonGroupMemberIds: null,
                }"
                :companies="filteredCompanies"
              />
            </div>

            <!-- location -->
            <div
              v-if="relevantLocations.length > 1"
              class="dropdown d-inline-block mr-4 mb-2 filter-container"
            >
              <h6 class="header-pretitle mt-3">Locations</h6>
              <GroupingFilter
                :nonGroupMembers="relevantLocations"
                nonGroupMemberLabel="Locations"
                memberType="locations"
                @update="groupingUpdate"
                :init="{
                  nonGroupMemberIds: selectFirstFilterLocations,
                  groupIds: null,
                  disabledNonGroupMemberIds: null,
                }"
                :key="groupRefreshKey"
                :isLoading="groupsPending"
              />
            </div>

            <!-- behavior -->
            <div class="d-inline-block mb-2 mr-4 filter-container">
              <h6 class="header-pretitle mt-3">Behavior</h6>
              <AudienceActionFilter
                @updateActions="filterByAction"
                :initialActions="selectAllFilterActions"
              />
            </div>

            <!-- rating -->
            <div class="d-inline-block mb-2 mr-4 filter-container">
              <h6 class="header-pretitle mt-3">Rating</h6>
              <AudienceRatingFilter
                @updateRatings="filterByRating"
                :surveyFilter="selectFirstFilterRatingSurveyFilter"
              />
            </div>

            <!-- tags -->
            <div class="d-inline-block mb-2 mr-4 filter-container">
              <h6 class="header-pretitle mt-3">Tags</h6>
              <AudienceTagFilter @update="filterByTags" :initialTagIds="selectFirstFilterTags" />
            </div>

            <!-- only show custom questions filters if not an org campaign -->
            <div v-if="selectActiveCompany._id !== 'ALL_COMPANIES'">
              <!-- custom questions -->
              <div
                class="d-inline-block mb-2 mr-4 filter-container"
                v-if="selectActiveCompany.customQuestionsEnabled"
              >
                <h6 class="header-pretitle mt-3">Question Answers</h6>
                <CustomQuestionAnswerFilter
                  @updateCQAnswers="filterByCQAnswer"
                  :initial="selectFirstFilterCustomQuestionAnswer"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GroupingFilter from '@/components/Modules/Filters/GroupingFilter'
import DateRangeFilter from '../Filters/DateRangeFilter'
import AudienceActionFilter from '../Filters/AudienceActionFilter'
import AudienceRatingFilter from '../Filters/AudienceRatingFilter'
import CustomQuestionAnswerFilter from '../Filters/CustomQuestionAnswerFilter'
import AudienceTagFilter from '../Filters/AudienceTagFilter'
import { createNamespacedHelpers } from 'vuex'

const UserModule = createNamespacedHelpers('user')
const CustomFilterModule = createNamespacedHelpers('customFilter')
const CompanyModule = createNamespacedHelpers('company')
const LocationModule = createNamespacedHelpers('location')
const TagModule = createNamespacedHelpers('tag')
const GroupModule = createNamespacedHelpers('group')

export default {
  name: 'AudienceFilters',
  components: {
    DateRangeFilter,
    GroupingFilter,
    AudienceActionFilter,
    AudienceRatingFilter,
    CustomQuestionAnswerFilter,
    AudienceTagFilter,
  },
  data: () => ({
    groupsPending: false,
    tagOptions: [],
    tagsFilters: [],
    filteredGroups: null,
    filteredGroupMembers: null,
    filteredLocations: null,
    filteredDisabledLocations: null,
    filteredCompanies: [],
    initialized: false,
  }),
  async mounted() {
    this.loadRelevantGroups()
    this.applyFilters({})
    this.initialized = true
    this.$emit('filtersInitialized')
  },
  computed: {
    ...UserModule.mapGetters(['selectOnlineUser']),
    ...CustomFilterModule.mapGetters([
      'selectCustomFilter',
      'selectCount',
      'selectFirstFilterDateSettings',
      'selectFirstFilterCompanies',
      'selectFirstFilterLocations',
      'selectAllFilterActions',
      'selectFirstFilterRatingSurveyFilter',
      'selectFirstFilterCustomQuestionAnswer',
      'selectFirstFilterTags',
    ]),
    ...GroupModule.mapGetters(['selectGroups']),
    ...LocationModule.mapGetters(['selectLocationsByCompanies']),
    ...CompanyModule.mapGetters(['selectActiveCompany', 'selectRelevantCompanies']),
    ...TagModule.mapGetters(['selectCompanyTags']),
    relevantCompanies() {
      return this.selectRelevantCompanies.filter((c) => c.status !== 'cancelled')
    },
    relevantLocations() {
      const companyIds = this.filteredCompanies?.length
        ? this.filteredCompanies
        : this.selectRelevantCompanies.map((c) => c._id)
      return this.selectLocationsByCompanies(companyIds) || []
    },
    groupRefreshKey() {
      return (
        (this.selectActiveCompany?._id || 'NO_COMPANY') +
        (this.filteredCompanies?.join('') || '') +
        (this.relevantLocations.length || 0) +
        this.selectGroups.map((g) => g._id).join('')
      )
    },
  },
  methods: {
    ...GroupModule.mapActions(['selectGroupLocationIds', 'fetchGroups']),
    ...TagModule.mapActions(['getTags']),
    ...CustomFilterModule.mapActions(['setCustomFilter', 'fetchAudiencePreview']),
    async groupingUpdate(val) {
      this.filteredGroups = val && val.groupIds && val.groupIds.length ? val.groupIds : null
      this.filteredGroupMembers =
        val && val.memberIds && val.memberIds.length ? val.memberIds : null
      this.filteredLocations =
        val && val.nonGroupMemberIds && val.nonGroupMemberIds.length ? val.nonGroupMemberIds : null
      this.filteredDisabledLocations =
        val && val.disabledNonGroupMemberIds && val.disabledNonGroupMemberIds.length
          ? val.disabledNonGroupMemberIds
          : null

      let locationsToFilter = [
        ...new Set([
          ...(this.filteredLocations || []),
          ...(this.filteredDisabledLocations || []),
          ...(this.filteredGroupMembers || []),
        ]),
      ]

      this.applyFilters({
        locationFilter: {
          locations: locationsToFilter.length
            ? locationsToFilter
            : this.filteredGroups?.length
            ? await this.selectGroupLocationIds(this.filteredGroups)
            : [],
        },
      })
    },
    async loadRelevantGroups() {
      this.groupsPending = true
      await this.fetchGroups({
        memberType: 'location',
        companyIds: this.filteredCompanies.length
          ? this.filteredCompanies
          : this.relevantCompanies.map((c) => c._id),
      })
      this.groupsPending = false
    },
    async companyGroupingUpdate(val) {
      this.filteredCompanies = val?.nonGroupMemberIds || []
      this.loadRelevantGroups()
      // set company, but reset locations any time companies change
      this.applyFilters({
        companyFilter: { companies: this.filteredCompanies },
        locationFilter: { locations: [] },
      })
    },
    filterByDate(dateFilters = {}) {
      this.applyFilters({ dateFilter: { ...dateFilters } })
    },
    filterByRating(surveyFilters) {
      this.applyFilters({ ratingFilter: { ...surveyFilters } })
    },
    filterByAction(actions) {
      this.applyFilters({ actionFilter: { actions } })
    },
    filterByTags(tags) {
      this.applyFilters({ tagFilter: { tags } })
    },
    filterByCQAnswer(customQuestionAnswers) {
      this.applyFilters({ customQuestionAnswerFilter: { ...customQuestionAnswers } })
    },
    async applyFilters(filters) {
      this.setCustomFilter({ filterComponent: 'campaign-creation', ...filters })
      try {
        await Promise.all([this.fetchAudiencePreview({}), this.getTags()])
        this.$emit('previewLoaded')
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>

<style scoped lang="scss">
.date-filter {
  top: 0px;
}
.filter-container {
  height: 80px;
  // overflow: hidden;
  float: left;
  // margin: 0px !important;
  padding: 0px !important;
}
.tag-filters {
  width: auto;
  min-width: 120px;
}
.customer-count {
  color: #6e84a3;
}
.customer-text {
  color: #95aac9;
}
@media (max-width: 991.98px) {
  .filter-container {
    // margin-right: 0rem !important;
    display: block !important;
    clear: both;
  }
}
</style>
