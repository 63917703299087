<template>
  <div class="container pl-0 ml-0">
    <div class="row justify-content-start" v-if="initialized">
      <!-- date -->
      <div class="dropdown col-auto">
        <h6 class="header-pretitle mt-3">Date Range</h6>
        <DatePicker
          @update="dateUpdate"
          :minDate="activeCompany.created"
          :dateRange="filteredDateRange"
        />
      </div>

      <!-- company -->
      <div class="dropdown col-auto" v-if="activeCompany._id === 'ALL_COMPANIES'">
        <h6 class="header-pretitle mt-3">Companies</h6>
        <CompanyFilter
          @update="companyUpdate"
          :wideMode="true"
          :companies="selectInsightsCompanies"
          :activeCompany="activeCompany"
          :preselectedCompanies="this.filteredCompanies"
        />
      </div>

      <!-- location -->
      <div class="dropdown col-auto">
        <h6 class="header-pretitle mt-3">Locations</h6>
        <GroupingFilter
          :nonGroupMembers="locations"
          nonGroupMemberLabel="Locations"
          memberType="location"
          @update="groupingUpdate"
          :init="{
            nonGroupMemberIds: this.filteredLocations,
            groupIds: this.filteredGroups,
          }"
          :key="locationRefreshKey"
          :companies="filteredCompanies"
        />
      </div>

      <!-- source -->
      <div class="dropdown col-auto">
        <h6 class="header-pretitle mt-3">Sources</h6>
        <SourceFilter
          @updateSources="sourceUpdate"
          :init="filteredSources"
          :multiCompany="true"
          :forInsights="true"
        />
      </div>

      <!-- keywords -->
      <div class="dropdown col-auto" v-if="isEnabled('INSIGHTS_SEARCH')">
        <h6 class="header-pretitle mt-3">Keyword Search</h6>
        <TagFilter
          placeholder="Use a comma to search multiple keywords"
          @apply="keywordUpdate"
          :init="filteredKeywords"
        />
      </div>
    </div>
  </div>
</template>

<script>
import GroupingFilter from '@/components/Modules/Filters/GroupingFilter'
import DatePicker from '@/components/Modules/Filters/DatePicker'
import SourceFilter from '@/components/Modules/Filters/SourceFilter'
import CompanyFilter from '@/components/Modules/Filters/CompanyFilter'
import TagFilter from '@/components/Modules/Filters/TagFilter'
import { createNamespacedHelpers } from 'vuex'

const InsightsModule = createNamespacedHelpers('insights')
const CompanyModule = createNamespacedHelpers('company')
const LocationModule = createNamespacedHelpers('location')
const GroupModule = createNamespacedHelpers('group')
const FeatureFlagModule = createNamespacedHelpers('featureFlag')

export default {
  name: 'InsightsFilters',
  components: {
    GroupingFilter,
    DatePicker,
    SourceFilter,
    CompanyFilter,
    TagFilter,
  },
  data: () => ({
    initialized: false,
    filteredDateRange: null,
    filteredGroups: null,
    filteredGroupMembers: null,
    filteredLocations: null,
    filteredDisabledLocations: null,
    filteredSources: null,
    filteredCompanies: null,
    filteredKeywords: null,
  }),
  mounted() {
    this.initializeData()
  },
  beforeDestroy() {
    // this is for switching from all companies to single company
    if (this.activeCompany._id !== 'ALL_COMPANIES' && this.$route.name === 'insights-summary') {
      this.$router.push('/insights/summary')
    }
  },
  computed: {
    ...LocationModule.mapGetters([
      'selectActiveLocations',
      'selectDisabledLocations',
      'selectLocation',
      'selectInsightsLocations',
    ]),
    ...CompanyModule.mapGetters(['activeCompany', 'selectInsightsCompanies']),
    ...FeatureFlagModule.mapGetters(['isEnabled']),
    locations() {
      let locations = this.selectActiveLocations.filter((l) => !l.hideFromReports)
      if (this.filteredCompanies?.length) {
        locations = locations.filter((l) =>
          this.filteredCompanies?.includes(l.company || this.selectLocation(l)?.company)
        )
      }
      return locations
    },
    locationRefreshKey() {
      return this.filteredCompanies?.join('') || 0
    },
  },
  methods: {
    ...InsightsModule.mapActions(['applyFilters', 'setLastSummaryUrl']),
    ...GroupModule.mapActions(['selectGroupLocationIds']),
    ...CompanyModule.mapActions(['setAllCompanies']),
    async initializeData() {
      this.filteredDateRange = this.$route.query.dateRange
      this.filteredCompanies = this.$route.query.c
      this.filteredLocations = this.$route.query.locs
      this.filteredGroups = this.$route.query.groups
      this.filteredDisabledLocations = this.$route.query.disLocs
      this.filteredSources = this.$route.query.sources
      this.filteredKeywords = this.$route.query.keywords

      if (this.filteredCompanies && !Array.isArray(this.filteredCompanies)) {
        this.filteredCompanies = [this.filteredCompanies]
      }
      if (this.filteredCompanies?.length > 1) {
        await this.setAllCompanies()
      }
      if (this.filteredGroups && !Array.isArray(this.filteredGroups)) {
        this.filteredGroups = [this.filteredGroups]
      }
      if (this.filteredLocations && !Array.isArray(this.filteredLocations)) {
        this.filteredLocations = [this.filteredLocations]
      }
      if (this.filteredDisabledLocations && !Array.isArray(this.filteredDisabledLocations)) {
        this.filteredDisabledLocations = [this.filteredDisabledLocations]
      }
      if (this.filteredSources && !Array.isArray(this.filteredSources)) {
        this.filteredSources = [this.filteredSources]
      }
      if (this.filteredKeywords && !Array.isArray(this.filteredKeywords)) {
        this.filteredKeywords = [this.filteredKeywords]
      }
      if (!(await this.accessPermitted())) {
        this.$router.replace({ query: {} })
        this.$emit('refresh')
        this.$notify({ text: 'You lack permissions needed to view this link', type: 'error' })
      } else {
        this.initialized = true
      }
    },
    groupingUpdate(val) {
      this.filteredGroups = val && val.groupIds && val.groupIds.length ? val.groupIds : null
      this.filteredGroupMembers =
        val && val.memberIds && val.memberIds.length ? val.memberIds : null
      this.filteredLocations =
        val && val.nonGroupMemberIds && val.nonGroupMemberIds.length ? val.nonGroupMemberIds : null
      this.formatAndApplyFilters()
    },
    companyUpdate(val) {
      this.filteredCompanies = val
      this.formatAndApplyFilters()
    },
    dateUpdate(val) {
      this.filteredDateRange = val
      this.formatAndApplyFilters()
    },
    sourceUpdate(val) {
      this.filteredSources = val
      this.formatAndApplyFilters()
    },
    keywordUpdate(val) {
      this.filteredKeywords = [...val]
      this.formatAndApplyFilters()
    },
    async formatAndApplyFilters() {
      let locationsToFilter = [
        ...new Set([...(this.filteredLocations || []), ...(this.filteredGroupMembers || [])]),
      ]
      this.applyFilters({
        companies: this.filteredCompanies?.length ? this.filteredCompanies : null,
        locations: locationsToFilter.length
          ? locationsToFilter
          : this.filteredGroups?.length
          ? await this.selectGroupLocationIds(this.filteredGroups)
          : null,
        dateRange:
          this.filteredDateRange && Object.keys(this.filteredDateRange).length
            ? this.filteredDateRange
            : null,
        sources: this.filteredSources,
        keywords: this.filteredKeywords,
      })
      this.$emit('filtersApplied')
      this.$router
        .replace({
          path: this.$route.path,
          query: {
            dateRange: this.filteredDateRange,
            ...(this.filteredGroups && this.filteredGroups.length
              ? { groups: this.filteredGroups }
              : {
                  ...(this.filteredLocations?.length ? { locs: this.filteredLocations } : {}),
                }),
            ...(this.filteredSources?.length ? { sources: this.filteredSources } : {}),
            ...(this.filteredCompanies?.length ? { c: this.filteredCompanies } : {}),
            ...(this.filteredKeywords?.length ? { keywords: this.filteredKeywords } : {}),
          },
        })
        .catch((err) => {
          if (err.name !== 'NavigationDuplicated') throw err
        })
      this.setLastSummaryUrl(this.$route.fullPath)
    },
    async accessPermitted() {
      let permitted = true
      if (this.filteredCompanies?.length) {
        permitted = this.filteredCompanies.every((c) =>
          this.selectInsightsCompanies.find((ic) => ic._id === c)
        )
      }
      if (this.filteredLocations?.length) {
        permitted = this.filteredLocations.every((l) =>
          this.selectInsightsLocations.find((il) => il._id === l)
        )
      }
      return permitted
    },
  },
}
</script>
