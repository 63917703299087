import DOMPurify from 'dompurify'

// TODO: when moving to OWA make this reference the store
const SMART_TAGS = [
  { name: 'Customer First Name', value: '<Customer First Name>' },
  { name: 'Author First Name', value: '<Author First Name>' },
  { name: 'Company Name', value: '<Company Name>' },
  { name: 'Review Link', value: '<Review Link>' },
  { name: 'Survey Link', value: '<Survey Link>' },
  { name: 'Order Link', value: '<Order Link>' },
  { name: 'Location Name', value: '<Location Name>' },
  { name: 'Location City', value: '<Location City>' },
]

export const sanitizeInput = (input) => {
  // Step 1: Replace the placeholders with custom tags
  let sanitizedInput = input
  SMART_TAGS.forEach((tag) => {
    const customTag = `<custom-${tag.name.replace(/\s+/g, '-').toLowerCase()}></custom-${tag.name
      .replace(/\s+/g, '-')
      .toLowerCase()}>`
    sanitizedInput = sanitizedInput.replace(new RegExp(tag.value, 'g'), customTag)
  })

  // Step 2: Sanitize the input with DOMPurify (allowing custom tags)
  const cleanHTML = DOMPurify.sanitize(sanitizedInput, {
    ALLOWED_TAGS: [
      'custom-customer-first-name',
      'custom-author-first-name',
      'custom-company-name',
      'custom-review-link',
      'custom-survey-link',
      'custom-order-link',
      'custom-location-name',
      'custom-location-city',
    ],
  })

  // Step 3: Revert custom tags back to their original placeholders
  let finalHTML = cleanHTML
  SMART_TAGS.forEach((tag) => {
    const customTag = `<custom-${tag.name.replace(/\s+/g, '-').toLowerCase()}></custom-${tag.name
      .replace(/\s+/g, '-')
      .toLowerCase()}>`
    finalHTML = finalHTML.replace(new RegExp(customTag, 'g'), tag.value)
  })

  // Step 4: Prevent encoding of < and > symbols
  finalHTML = finalHTML.replace(/&lt;/g, '<').replace(/&gt;/g, '>')

  return finalHTML
}
