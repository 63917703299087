<template>
  <FadeTransition>
    <div class="mt-4">
      <KeywordDetailsModal ref="keywordDetailsModal" />
      <KeywordListHeader />
      <div v-if="keywords.length == 0" class="mt-7"><KeywordOffState /></div>
      <div class="card" v-else>
        <b-table
          :fields="fields"
          :items="keywords"
          responsive
          hover
          :table-class="['card-table', 'table-nowrap']"
          small
          :tbody-tr-class="getRowClass"
        >
          <template #head()="{ label, field: { key, sortable, tooltip } }">
            <span v-b-tooltip.hover :title="tooltip">
              {{ label }}
            </span>
            <template v-if="sortable">
              <template v-if="sortBy !== key">
                <i class="fe fe-arrow-down"></i>
                <i class="fe fe-arrow-up"></i>
              </template>
              <i v-else-if="sortDesc" class="fe fe-arrow-down" style="font-weight: bold"></i>
              <i v-else class="fe fe-arrow-up" style="font-weight: bold"></i>
            </template>
          </template>

          <template #cell(tag)="{ value }"> {{ value.name }} </template>

          <template #cell(actions)="data">
            <div class="d-flex justify-content-end">
              <button
                class="btn btn-outline-primary btn-sm btn-min-width"
                @click="showDetails(data.item)"
              >
                Details
              </button>
              <div class="d-inline ml-3">
                <KeywordListDropdown
                  :keyword="data.item"
                  @edit="editKeyword(data.item)"
                  @archive="confirmArchive(data.item)"
                />
              </div>
            </div>
          </template>
        </b-table>
      </div>
    </div>
  </FadeTransition>
</template>

<script>
import FadeTransition from '@/components/Transitions/FadeTransition'
import KeywordListHeader from '@/components/Modules/Keywords/KeywordListHeader'
import KeywordService from '@/services/KeywordService'
import KeywordDetailsModal from '@/components/Modals/KeywordDetailsModal'
import KeywordListDropdown from './KeywordListDropdown'
import KeywordOffState from './KeywordOffState'
import { createNamespacedHelpers } from 'vuex'

const keywordModule = createNamespacedHelpers('keyword')
const campaignModule = createNamespacedHelpers('campaign')
const companyModule = createNamespacedHelpers('company')

// @group Keywords
// This component is the main display for the keywords page. It shows the header, table, and details modal.
export default {
  name: 'KeywordList',
  components: {
    FadeTransition,
    KeywordListHeader,
    KeywordListDropdown,
    KeywordDetailsModal,
    KeywordOffState,
  },
  data: () => ({
    fields: [
      { key: 'word', sortable: true, class: 'align-middle text-uppercase' },
      { key: 'description', class: 'align-middle' },
      { key: 'action', class: 'align-middle' },
      {
        key: 'tag',
        label: 'Customer Tag',
        sortable: true,
        class: 'align-middle',
        tooltip: 'The tag that will be applied to any customer that texts the keyword',
      },
      { key: 'actions', label: '', class: 'align-middle' },
    ],
    sortBy: '',
  }),
  mounted() {
    this.getKeywords()
  },
  computed: {
    ...keywordModule.mapGetters(['keywords']),
    ...campaignModule.mapState(['campaign']),
    ...companyModule.mapState(['activeCompany']),
  },
  watch: {
    activeCompany: {
      immediate: true,
      async handler() {
        await this.getKeywords()
      },
    },
  },
  methods: {
    ...keywordModule.mapMutations(['setKeywordToEdit']),
    ...keywordModule.mapActions(['getKeywords', 'archiveKeyword']),
    ...campaignModule.mapMutations(['setCampaignToEdit', 'resetCampaign']),
    ...campaignModule.mapActions(['toggleCampaign', 'queryCampaigns']),
    // @vuese
    // Check if user is sure they want to archive the keyword
    // @arg Keyword: the keyword `Object` to archive. RETURNS: none
    confirmArchive(keyword) {
      this.$bvModal
        .msgBoxConfirm(`Are you sure you want to archive keyword: ${keyword.word.toUpperCase()}?`, {
          title: 'Please Confirm!',
          okVariant: 'danger',
          okTitle: 'Archive',
          footerClass: 'p-4',
          centered: true,
        })
        .then((value) => {
          if (value) this.archive(keyword)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // @vuese
    // Archives the Keyword
    // @arg Keyword - the keyword `Object` to archive. RETURNS: none
    async archive(keyword) {
      // check if there is a campaign associated with keyword and disable if it is active
      if (keyword.campaign) {
        if (!(await this.disableLinkedCampaign(keyword))) {
          return
        }
      }
      this.archiveKeyword({ keywordId: keyword._id, notify: this.$notify })
    },
    // @vuese
    // If an archived Keyword had a campaign this will pause it.
    // @arg Keyword - the keyword `Object` to archive. RETURNS: `True`/`False`
    async disableLinkedCampaign(keyword) {
      await this.queryCampaigns()
      this.setCampaignToEdit(keyword.campaign._id)
      try {
        if (this.campaign.status != 'paused') {
          await this.toggleCampaign(keyword.campaign._id)
          this.$notify({ text: `Successfully disabled associated campaign` })
        }
        await this.resetCampaign()
        return true
      } catch (error) {
        this.$notify({ type: 'error', text: 'Failed to disable campaign' })
        return false
      }
    },
    // @vuese
    // Opens a modal showing the selected Keyword's details
    // @arg Keyword - the keyword `Object` selected. RETURNS: none
    showDetails(keyword) {
      this.$refs.keywordDetailsModal.initialize(keyword)
      // Displays the Keyword details modal
      // @arg Uses Bootstrap Vue's preset event name and the name of the modal
      this.$root.$emit('bv::show::modal', 'keywordDetailsModal')
    },
    // @vuese
    // Navigates to the edit page and prepopulates the form
    // @arg Keyword - the keyword `Object` to archive. RETURNS: none
    editKeyword(keyword) {
      this.setKeywordToEdit(keyword)
      this.$router.push('create-keyword')
    },
    getRowClass(item) {
      const keyword = String(item.word).toLowerCase()
      const disabledWords = ['help', 'menu', 'win', 'order']
      return disabledWords.includes(keyword) ? 'custom-disabled-row' : ''
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep tr > [aria-sort] {
  background-image: none !important;
}
.btn-min-width {
  min-width: 70px;
}
::v-deep(.custom-disabled-row) {
  background-color: #f8f9fa;
  pointer-events: none;
  opacity: 0.6;
}
</style>
