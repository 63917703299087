<template>
  <b-modal ref="modal" id="editEmployeeModal" hide-footer hide-header body-class="p-0">
    <div class="modal-card card">
      <div class="card-header">
        <div class="row align-items-center">
          <div class="col">
            <h4 class="card-header-title" id="exampleModalCenterTitle">
              Edit {{ employee.firstName }}
            </h4>
          </div>
          <div class="col-auto">
            <button @click="close" type="button" class="close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
        </div>
      </div>
      <div class="card-body" id="editEmployeeModalBody" style="max-height: 650px">
        <div v-if="employee._id" class="row">
          <div class="col-12 mb-4">
            <RolePicker
              ref="rolePicker"
              v-model="employee.role"
              :show-admin="onlineUser.role === 'admin'"
              @input="roleChanged"
            />
          </div>
          <div class="col-12 mb-4">
            <CustomerLocationFilter
              ref="locationAccessPicker"
              v-on:updateLocations="updateLocations"
              :wideMode="true"
              :forTeam="true"
            />
          </div>
          <div class="col-12 mb-4">
            <hr class="mt-0 mb-4" />
            <AccountNotificationSettings :activeUser="employee" />
          </div>
        </div>
      </div>
      <div class="card-header">
        <div class="row">
          <div class="col-12 mt-3 mb-2">
            <button
              @click="update"
              :disabled="!formValid || isLoading"
              class="btn btn-primary col-12"
              :class="{ 'is-loading': isLoading }"
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import RolePicker from '@/components/Modules/User/RolePicker'
import CustomerLocationFilter from '@/components/Modules/Customer/CustomerFilters/CustomerLocationFilter'
import AccountNotificationSettings from '@/components/Modules/User/Account/AccountNotificationSettings'
import UserService from '@/services/UserService'
import { createNamespacedHelpers } from 'vuex'
const UserModule = createNamespacedHelpers('user')
const CompanyModule = createNamespacedHelpers('company')

export default {
  name: 'EditEmployeeModal',
  components: {
    RolePicker,
    CustomerLocationFilter,
    AccountNotificationSettings,
  },
  data: () => ({
    isLoading: false,
    employee: { locations: [] },
  }),
  computed: {
    ...UserModule.mapState(['onlineUser']),
    ...CompanyModule.mapState(['activeCompany']),
    formValid() {
      return (
        (this.onlineUser.role === 'admin' && this.employee.role == 'admin') ||
        (this.employee._id && this.employee.role && this.employee.locations.length)
      )
    },
  },
  methods: {
    setEmployee(employee) {
      this.employee = { ...employee }

      setTimeout(() => {
        this.$refs.rolePicker.reset(employee.role)
        this.$refs.locationAccessPicker.reset(employee.locations)
      })
    },
    updateLocations(locations) {
      this.employee.locations = locations
    },
    roleChanged() {
      if (this.employee.role === 'admin') {
        this.employee.locations = []
      }
    },
    async update() {
      this.isLoading = true

      await UserService.update({
        userId: this.employee._id,
        fields: {
          role: this.employee.role,
          locations: this.employee.locations?.map((l) => ({ _id: l._id })),
        },
      })

      this.$notify({ text: 'Successfully saved user', type: 'success' })
      this.close()
      this.$emit('updated')

      setTimeout(() => {
        this.isLoading = false
        this.$refs.rolePicker.reset()
        this.$refs.locationAccessPicker.reset()
      }, 400)
    },
    close() {
      this.$refs.modal.hide()
    },
  },
}
</script>
