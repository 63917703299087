<template>
  <div>
    <div v-if="!initialized">
      <div class="mb-4 mx-auto" style="max-width: 800px" v-for="index in 4" :key="index">
        <b-skeleton height="150px" />
      </div>
    </div>
    <div class="row justify-content-center" v-else>
      <div class="col-12 p-0" style="max-width: 800px">
        <!-- Promotion -->
        <div class="card shadow">
          <div class="px-4 pt-4 border-bottom">
            <div class="h3 card-title mb-4">
              Add an Offer
              <span class="h4 text-muted">(Optional)</span>
            </div>
            <div class="h5 card-subtitle text-muted">
              Give customers an incentive to answer your Custom Question List
            </div>
          </div>
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col-12 col-md-6">
                <PromoDropdown
                  ref="questionPromoDropdown"
                  :large="true"
                  :includeModal="true"
                  :includeUniquePromos="false"
                  @selected="setPromo"
                  @reset="resetPromo"
                  @updatePromo="updatePromo"
                  source="customQuestion"
                />
              </div>
              <div class="col-12 col-md-6 border-left">
                <div class="my-2" style="font-style: italic">"{{ textMessage }}"</div>
                <div class="input-group" v-if="selectedPromo">
                  <input
                    v-model="promoWording"
                    type="text"
                    placeholder="Insert desired Promo wording here"
                    class="form-control"
                    @keyup.enter="setPromoWording(promoWording)"
                  />
                  <div class="input-group-append">
                    <button class="btn btn-primary" @click="setPromoWording(promoWording)">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 'Thank you' wording -->
        <div class="card shadow">
          <div class="px-4 pt-4 border-bottom">
            <div class="h3 card-title mb-4">
              Custom 'Thank You' Message
              <span class="h4 text-muted">(Optional)</span>
            </div>
            <div class="h5 card-subtitle text-muted">
              This message will show below "Thank you, (customer name)"
            </div>
          </div>
          <div class="card-body">
            <div class="input-group">
              <input
                v-model="customThanks"
                type="text"
                placeholder="Your feedback is very important to us."
                class="form-control"
                @keyup.enter="setCustomThankYou(customThanks)"
              />
              <div class="input-group-append">
                <button class="btn btn-primary" @click="setCustomThankYou(customThanks)">
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- custom invite text wording -->
        <div class="card shadow">
          <div class="px-4 pt-4 border-bottom">
            <div class="h3 card-title mb-4">
              Custom Invite Text
              <span class="h4 text-muted">(Optional)</span>
            </div>
            <div class="h5 card-subtitle text-muted">
              This message will overwrite the default invite to answer custom questions
            </div>
          </div>
          <div class="card-body">
            <div class="form-group mb-2">
              <textarea
                v-model="customInviteText"
                ref="body"
                class="form-control sms text-message-orig"
                id="invite-textbox"
                maxlength="1000"
                name="content"
                :placeholder="`Hi <Customer First Name>, please answer <Question Count> more questions to receive <Offer>`"
                rows="4"
                @input="sanitizeCustomInvite"
              ></textarea>
            </div>
            <div class="mb-4 mt-3">
              <h6 class="header-pretitle">
                Smart Tags
                <span
                  class="fe fe-help-circle"
                  v-b-popover.hover.top="
                    `Add these to your message, and they will be replaced with their real values. For example, 
                  <Customer First Name> could turn to Jane if you are sending a message to Jane.`
                  "
                ></span>
              </h6>
              <a
                v-for="tag in smartTags"
                :key="tag.value"
                class="btn btn-white btn-sm mb-2 d-inline-block mr-2"
                href="javascript:;"
                @click="insertTagAtCursor(tag.value)"
              >
                {{ tag.name }}
              </a>
            </div>
            <div>
              <a
                @click="setCustomInviteText(customInviteText)"
                href="javascript:;"
                class="btn btn-block btn-lg btn-primary"
                :disabled="isLoading"
                :class="{ 'is-loading': isLoading }"
                >Save</a
              >
            </div>
          </div>
        </div>
        <!-- delay -->
        <div class="card shadow">
          <div class="px-4 pt-4 border-bottom">
            <div class="h3 card-title mb-4">Delay</div>
            <div class="h5 card-subtitle text-muted">
              Adjust the time between the initial survey and when the custom questions are sent. If
              the delay would cause the questions to be sent outside of normal texting hours, we
              will send them the next morning.
            </div>
          </div>
          <div class="card-body">
            <div>
              <b-form-spinbutton
                @change="setDelay"
                id="delay-input"
                max="48"
                min="0"
                v-model="delay"
                inline
              ></b-form-spinbutton>
              <label for="delay-input" class="ml-2">hours after initial survey</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PromoDropdown from '@/components/Modules/Promo/PromoDropdown'
import { createNamespacedHelpers } from 'vuex'
import { debounce } from 'lodash-es'
import { sanitizeInput } from '@/lib/validation'

const CustomQuestionModule = createNamespacedHelpers('customQuestion')

export default {
  name: 'SettingsView',
  components: {
    PromoDropdown,
  },
  data: () => ({
    initialized: false,
    delay: undefined,
    selectedPromo: '',
    promoWording: '',
    textMessage: '',
    customThanks: '',
    customInviteText: '',
    tagsList: [
      {
        name: 'Customer First Name',
        value: '<Customer First Name>',
      },
      {
        name: 'Question Count',
        value: '<Question Count>',
      },
      {
        name: 'Offer',
        value: '<Offer>',
      },
    ],
    isLoading: false,
  }),
  async mounted() {
    try {
      await this.initialize()
    } catch (error) {
      console.log(error)
      this.$notify({ type: 'error', text: 'Failed to get settings' })
    }
    this.initialized = true
    this.setDelay = debounce((v) => {
      this.updateDelay(v)
    }, 1500)
  },
  computed: {
    ...CustomQuestionModule.mapGetters(['settings']),
    smartTags() {
      return this.selectedPromo ? this.tagsList : this.tagsList.filter((t) => t.name !== 'Offer')
    },
  },
  methods: {
    ...CustomQuestionModule.mapActions([
      'getCustomQuestionSettings',
      'saveCustomQuestionSettings',
      'getMessage',
      'incrementRefreshKey',
    ]),
    sanitizeCustomInvite(e) {
      this.customInviteText = sanitizeInput(e.target.value)
    },
    async updateSettings({ fields }) {
      this.isLoading = true
      try {
        await this.saveCustomQuestionSettings({ fields })
        this.$notify({ text: 'Successfully updated settings' })
      } catch (error) {
        this.$notify({ type: 'error', text: 'Failed to update settings' })
      }
      this.isLoading = false
    },
    async initialize() {
      await this.getCustomQuestionSettings()
      this.textMessage = await this.getMessage()
      this.promoWording = this.settings.promoWording
      this.customThanks = this.settings.customThanks
      this.customInviteText = sanitizeInput(this.settings.customInviteText)
      this.delay = this.settings.delay ?? 24
    },
    async setPromo(promo) {
      await this.updateSettings({ fields: { promo: promo ? promo._id : null } })
      await this.initialize()
      this.updatePromo(promo)
    },
    async resetPromo() {
      await this.updateSettings({ fields: { promo: null, promoWording: null } })
      await this.initialize()
      this.updatePromo()
    },
    async updatePromo(promo) {
      this.selectedPromo = promo
      await this.initialize()
    },
    async setPromoWording(wording) {
      await this.updateSettings({ fields: { promoWording: wording || null } })
      await this.initialize()
    },
    async setCustomThankYou(wording) {
      await this.updateSettings({ fields: { customThanks: wording } })
    },
    async setCustomInviteText(wording) {
      await this.updateSettings({ fields: { customInviteText: wording } })
      await this.initialize()
    },
    async updateDelay(value) {
      await this.updateSettings({ fields: { delay: value } })
      // await this.initialize()
    },
    insertTagAtCursor(tagValue) {
      const textarea = document.getElementById('invite-textbox')
      if (textarea.selectionStart || textarea.selectionStart === 0) {
        var startPosition = textarea.selectionStart
        var endPosition = textarea.selectionEnd
        textarea.value =
          textarea.value.substring(0, startPosition) +
          tagValue +
          textarea.value.substring(endPosition, textarea.value.length)
        textarea.selectionEnd = startPosition + tagValue.length
      } else {
        textarea.value += tagValue
      }
      this.$refs.body.focus()
    },
  },
}
</script>
