import Config from '@/config'
import { trackEvent } from '@/lib/analytics'
import ConversationService from '@/services/ConversationService'
import NoteService from '@/services/NoteService'
import moment from 'moment-timezone'

const defaultState = () => ({
  activeConversation: null,
  conversationIds: [],
  conversationsById: {},
  conversations: [],
  filters: { resolved: false },
  conversationLimit: 50,
  skip: 0,
  nextPage: 1,
  searchedConversations: [],
  responseTimes: [],
  listExhausted: false,
  activeConversationLoading: false,
  fetchingResponseTimes: false,
  drawerLocationId: null,
  isMobileView: false,
  showDetailsPane: true,
  unresolvedCount: 0,
  openRates: {},

  // this is just a temp variable for debugging. remove once api spikes are done
  fetchTimestamp: null,
  fetchConversationTimestamp: null,
  fcPreviousData: null,
  setSpecificConversationTimestamp: null,
})

export default {
  namespaced: true,
  state: {
    ...defaultState(),
    isWebview: false,
    isDeeplink: false,
    searchString: undefined,
  },
  getters: {
    selectConversationIds: (state) => state.conversationIds,
    selectConversationById: (state) => (conversationId) => state.conversationsById[conversationId],
    selectConversations: (state) => state.conversations,
    selectSearchedConversations: (state) => state.searchedConversations,
    selectFilters: (state) => state.filters,
    selectConversationLimit: (state) => state.conversationLimit,
    selectSkip: (state) => state.skip,
    selectNextPage: (state) => state.nextPage,
    selectResponseTimes: (state) => state.responseTimes,
    selectResponseTimeById: (state) => (conversationId) => state.responseTimes[conversationId],
    selectListExhausted: (state) => state.listExhausted,
    selectActiveConversation: (state) => state.activeConversation,
    selectActiveConversationId: (state) => state.activeConversation?._id,
    selectActiveConversationCustomer: (state) => state.activeConversation?.customer,
    selectActiveConversationLocation: (state) => state.activeConversation?.location,
    selectActiveConversationCompanyId: (state) => state.activeConversation?.company,
    selectActiveConversationLoading: (state) => state.activeConversationLoading,
    selectFetchingResponseTimes: (state) => state.fetchingResponseTimes,
    selectDrawerLocationId: (state) => state.drawerLocationId,
    selectIsMobileView: (state) => state.isMobileView,
    selectShowDetailsPane: (state) => state.showDetailsPane,
    selectUnresolvedCount: (state) => state.unresolvedCount,
    selectOpenRates: (state) => state.openRates,
    selectIsSearching: (state) => (state.searchString ? true : false),
  },
  mutations: {
    SET_SKIP(state, skip) {
      state.skip = skip
    },
    SET_NEXT_PAGE(state, nextPage) {
      state.nextPage = nextPage
    },
    SET_SEARCHED_CONVERSATIONS(state, searchedConversations) {
      state.searchedConversations = [...state.searchedConversations, ...searchedConversations]
    },
    SET_CONVERSATIONS(state, conversations) {
      const newConversationIds = conversations.map((conversation) => conversation._id)
      let newConversations = [
        ...state.conversations.filter(
          (conversation) => !newConversationIds.includes(conversation._id)
        ),
        ...conversations,
      ]

      // there will be no filters for the winback drawers so don't run this next 'if'
      if (!state.filters.search && Object.keys(state.filters).length) {
        newConversations = newConversations
          .filter((conversation) => {
            if (!conversation.lastCommunication) {
              return false
            }
            return true
          })
          .sort(
            (a, b) =>
              !!a.resolved - !!b.resolved ||
              b.lastCommunication.date.localeCompare(a.lastCommunication.date)
          )
      }

      if (!state.isWebview && !state.isDeeplink) {
        if (state.filters.resolved) {
          newConversations = newConversations.filter((c) => c.resolved)
        } else if (state.filters.resolved === false) {
          newConversations = newConversations.filter((c) => !c.resolved)
        }
      }

      state.conversations = newConversations
      state.conversationIds = newConversations.map((c) => c._id)
      state.conversationsById = newConversations.reduce((acc, curr) => {
        acc[curr._id] = curr
        return acc
      }, {})

      if (state.activeConversation) {
        state.activeConversation = state.conversationsById[state.activeConversation?._id]
      }
    },
    SET_FILTERS(state, filters) {
      state.filters = filters
    },
    SET_RESPONSE_TIMES(state, responseTimes) {
      let newResponseTimes = { ...state.responseTimes }

      responseTimes.forEach((response) => {
        if (response) {
          newResponseTimes[response.conversationId] = response
        }
      })

      state.responseTimes = newResponseTimes
    },
    RESET_CONVERSATIONS(state) {
      state.conversationIds = []
      state.conversationsById = {}
      state.conversations = []
      state.listExhausted = false
      state.skip = 0
      state.nextPage = 1
      state.searchedConversations = []
    },
    RESET_STATE(state) {
      Object.assign(state, defaultState())
    },
    SET_LIST_EXHAUSTED(state, exhausted) {
      state.listExhausted = exhausted
    },
    SET_ACTIVE_CONVERSATION(state, conversation) {
      state.activeConversation = conversation
    },
    SET_ACTIVE_CONVERSATION_LOADING(state, status) {
      state.activeConversationLoading = status
    },
    ADD_USER_OPEN(state, userId) {
      state.activeConversation.opened.push(userId)
    },
    SET_FETCHING_RESPONSE_TIMES(state, status) {
      state.fetchResponseTimes = status
    },
    SET_DRAWER_LOCATION_ID(state, locationId) {
      state.drawerLocationId = locationId
    },
    SET_IS_MOBILE_VIEW(state, status) {
      state.isMobileView = status
    },
    SET_SHOW_DETAILS_PANE(state, status) {
      state.showDetailsPane = status
    },
    SET_CONVERSATION_COUNT(state, count) {
      state.unresolvedCount = count
    },
    SET_IS_WEBVIEW(state, status) {
      state.isWebview = status
    },
    SET_IS_DEEPLINK(state, status) {
      state.isDeeplink = status
    },
    SET_OPEN_RATES(state, openRates) {
      state.openRates = openRates
    },
    SET_SEARCH_STRING(state, searchString) {
      state.searchString = searchString
    },
  },
  actions: {
    async resetAllConversationData({ commit, dispatch }) {
      commit('RESET_STATE')
      await dispatch('timelineV2/resetAllTimelineData', null, { root: true })
    },

    async refreshActiveConversation({ dispatch, getters }) {
      await dispatch('setActiveConversation', getters.selectActiveConversation)
    },

    async fetchConversations({ state, commit, rootGetters, getters, dispatch }, { reset, trace }) {
      // for debugging api spikes: remove once resolved
      if (state.fetchTimestamp && moment().diff(state.fetchTimestamp, 'seconds', true) < 0.5) {
        trackEvent(null, 'fetchConversations', {
          trace,
          company: rootGetters['company/activeCompany']?._id,
          user: rootGetters['user/onlineUser']?._id,
        })
        return
      }
      state.fetchTimestamp = moment()
      // end debugging code
      if (!rootGetters['company/activeCompany']) return
      if (reset) {
        commit('RESET_CONVERSATIONS')
      }

      let companies =
        rootGetters['company/activeCompany']._id == 'ALL_COMPANIES'
          ? rootGetters['company/companies']
          : [rootGetters['company/activeCompany']]

      if (getters.selectFilters.search) {
        trackEvent(null, 'winback-web-search', {
          trace,
          company: rootGetters['company/activeCompany']?._id,
          user: rootGetters['user/onlineUser']?._id,
        })
      }

      let response = await ConversationService.fetchConversations({
        ...(getters.selectFilters.search ? { search: getters.selectFilters.search } : {}),
        limit: getters.selectConversationLimit,
        skip: getters.selectSkip,
        page: getters.selectNextPage,
        filters: {
          companyIds: companies.map((c) => c._id),
          ...(getters.selectFilters.locationIds?.length
            ? { locationIds: getters.selectFilters.locationIds }
            : {}),
          resolved: getters.selectFilters.resolved,
        },
      })

      const conversationList = response.body.data.conversations.filter((c) => c.customer)

      if (conversationList.length) {
        dispatch('fetchResponseTimes', conversationList)
      }

      if (getters.selectFilters.search) {
        commit('SET_SEARCHED_CONVERSATIONS', conversationList)
        if (conversationList.length && response.body.data.metadata.nextPage) {
          commit('SET_NEXT_PAGE', response.body.data.metadata.nextPage)
        } else {
          commit('SET_LIST_EXHAUSTED', true)
        }
      } else {
        if (response.body.data.conversations.length < getters.selectConversationLimit) {
          commit('SET_LIST_EXHAUSTED', true)
        }
        // store list of ids and conversations
        // increase skip
        commit('SET_CONVERSATIONS', conversationList)
        commit('SET_SKIP', getters.selectSkip + getters.selectConversationLimit)
      }
    },

    async setUnresolvedCount({ commit, rootGetters }) {
      if (!rootGetters['company/activeCompany']) return
      const companies =
        rootGetters['company/activeCompany']._id == 'ALL_COMPANIES'
          ? rootGetters['company/companies']
          : [rootGetters['company/activeCompany']]

      const result = await ConversationService.fetchCount({
        filters: {
          companyIds: companies.map((c) => c._id),
        },
      })

      commit('SET_CONVERSATION_COUNT', result.data.data.count)
    },

    updateFilters({ commit }, filters) {
      commit('SET_FILTERS', filters)
    },

    updateDrawerLocationId({ commit }, locationId) {
      commit('SET_DRAWER_LOCATION_ID', locationId)
    },

    async fetchResponseTimes({ commit }, conversations) {
      commit('SET_FETCHING_RESPONSE_TIMES', true)
      const response = await ConversationService.fetchResponseTimes({
        conversationIds: conversations
          .filter((c) => c.lastCommunication)
          .map((conversation) => {
            return {
              customer: conversation.customer._id,
              location: conversation.location,
              _id: conversation._id,
            }
          }),
      })

      commit('SET_RESPONSE_TIMES', response.body.data.responseTimes)
      commit('SET_FETCHING_RESPONSE_TIMES', false)
    },

    async setActiveConversation({ commit, dispatch, getters, rootGetters }, conversation) {
      commit('SET_ACTIVE_CONVERSATION_LOADING', true)
      await dispatch('timelineV2/clearTimelineData', null, { root: true })
      let matchedConversation
      if (conversation?._id) {
        matchedConversation = getters.selectFilters.search
          ? conversation
          : getters.selectConversationById(conversation._id)
        commit('SET_ACTIVE_CONVERSATION', matchedConversation)
      } else {
        let conversations = await dispatch('fetchConversation', {
          conversation,
          trace: 'setActiveConversation',
        })
        if (conversations.length) {
          matchedConversation = conversations.find(
            (conversation) => conversations[0]._id === conversation._id
          )
        }
      }

      if (!matchedConversation && conversation?.customer?._id) {
        commit('SET_ACTIVE_CONVERSATION', {
          customer: { _id: conversation.customer._id },
          company: { _id: rootGetters['company/activeCompany']._id },
        })
      } else {
        commit('SET_ACTIVE_CONVERSATION', matchedConversation)
        await dispatch('timelineV2/fetchTimeline', null, { root: true })
      }
      commit('SET_ACTIVE_CONVERSATION_LOADING', false)
    },

    async setSpecificConversation(
      { commit, dispatch, getters, rootGetters, state },
      { filters, trace }
    ) {
      // for debugging api spikes: remove once resolved
      if (
        state.setSpecificConversationTimestamp &&
        moment().diff(state.setSpecificConversationTimestamp, 'seconds', true) < 0.5
      ) {
        trackEvent(null, 'setSpecificConversation', {
          company: rootGetters['company/activeCompany']?._id,
          user: rootGetters['user/onlineUser']?._id,
          trace,
        })
        return
      }
      state.setSpecificConversationTimestamp = moment()
      // end debugging code

      const { customer, locationId, companyId } = filters
      const customerId = customer?._id
      if (!customerId) return
      commit('SET_ACTIVE_CONVERSATION_LOADING', true)

      const userActiveLocationsIds = rootGetters['location/selectActiveLocations'].map((l) => l._id)
      const customerActiveCompanyLocations = customer?.locations?.filter((l) =>
        userActiveLocationsIds.includes(l)
      )

      // fetch the conversation
      let payload = {
        filters: {
          companyIds: companyId ? [companyId] : [rootGetters['company/activeCompany']._id],
          locationIds:
            locationId || customerActiveCompanyLocations?.length === 1
              ? [locationId || customerActiveCompanyLocations[0]]
              : rootGetters['location/selectActiveLocations'].map((location) => location._id),
          customerIds: [customerId],
          requireLastCommunication: false,
        },
        limit: getters.selectConversationLimit,
      }

      const response = await ConversationService.fetchConversations(payload)
      const { conversations } = response.body.data

      // if no location is specified and there are multiple location options then use placehold conversation
      if (!locationId && !conversations?.length && customerActiveCompanyLocations?.length !== 1) {
        commit('SET_ACTIVE_CONVERSATION', {
          customer: { _id: customerId },
          company: { _id: rootGetters['company/activeCompany']._id },
        })
        commit('SET_ACTIVE_CONVERSATION_LOADING', false)
        return
      }

      // if no conversation for specified location, create one
      else if (
        (locationId || customerActiveCompanyLocations?.length === 1) &&
        !conversations?.length
      ) {
        const newConversation = await dispatch('createConversation', {
          locationId: locationId || customerActiveCompanyLocations[0],
          customerId: customerId,
          companyId: companyId || rootGetters['company/activeCompany']._id,
        })
        commit('SET_CONVERSATIONS', [newConversation])
        commit('SET_ACTIVE_CONVERSATION', newConversation)
      } else {
        commit('SET_CONVERSATIONS', conversations)
        commit('SET_ACTIVE_CONVERSATION', conversations[0])
      }
      await dispatch('timelineV2/fetchTimeline', null, { root: true })
      dispatch('timelineV2/scrollToLastEvent', null, { root: true })

      commit('SET_ACTIVE_CONVERSATION_LOADING', false)
    },

    // specifically for deeplinking from emails, sets conversation as the activeConversation
    async fetchSpecificConversation({ commit, dispatch, rootGetters }, conversationId) {
      const response = await ConversationService.fetchConversation(conversationId)

      const conversation = response.body.data
      if (conversation) {
        // check if company is different from active company, change companies if so
        if (
          !rootGetters['company/activeCompany'] ||
          rootGetters['company/activeCompany']._id !== conversation.company
        ) {
          const company = rootGetters['company/selectCompany'](conversation.company)
          if (process.env.NODE_ENV !== 'headless') {
            window.parent.postMessage(
              { company, event: 'CHANGE_ACTIVE_COMPANY' },
              Config.baseAppUrl
            )
          }
          await dispatch('company/setActiveCompany', company, { root: true })
        }
        commit('SET_ACTIVE_CONVERSATION', conversation)
        await dispatch('timelineV2/fetchTimeline', null, { root: true })
      }
    },

    async fetchConversation(
      { state, commit, dispatch, getters, rootGetters },
      { conversation, trace }
    ) {
      // for debugging api spikes: remove once resolved
      if (
        state.fetchConversationTimestamp &&
        moment().diff(state.fetchConversationTimestamp, 'seconds', true) < 0.5
      ) {
        trackEvent(null, 'fetchConversation(singular)', {
          conversation,
          company: rootGetters['company/activeCompany']?._id,
          user: rootGetters['user/onlineUser']?._id,
          isMobile: getters.selectIsMobileView,
          trace,
          'trace-previous': state.fcPreviousData?.previousTrace,
          'conversation-previous': state.fcPreviousData?.previousConversation,
          skip: getters.selectSkip,
          limit: getters.selectConversationLimit,
        })
      }
      state.fetchConversationTimestamp = moment()
      state.fcPreviousData = {
        previousConversation: conversation,
        previousTrace: trace,
      }
      // end debugging code

      let payload = {
        filters: {
          companyIds: rootGetters['dataCore/selectActiveCompanyIds'],
          locationIds: rootGetters['location/selectActiveLocations'].map(
            (location) => location._id
          ),
        },
        limit: getters.selectConversationLimit,
      }

      if (conversation._id) {
        payload.filters.conversationIds = [conversation._id]
      } else {
        payload.filters.customerIds = [conversation.customer._id]
      }

      const response = await ConversationService.fetchConversations(payload)

      if (response.body.success !== true) {
        throw response.body
      }

      let { conversations } = response.body.data

      if (conversations.length) {
        dispatch('fetchResponseTimes', conversations)
      }

      commit('SET_CONVERSATIONS', conversations)

      return conversations
    },

    async open({ commit, rootState }, { conversationId }) {
      if (!conversationId) return
      const response = await ConversationService.markOpen({ conversationId })
      commit('ADD_USER_OPEN', `${rootState.user.onlineUser._id}`)
    },

    async createConversation({ dispatch, commit, getters }, { customerId, companyId, locationId }) {
      const response = await ConversationService.createConversation({
        customer: customerId,
        location: locationId,
        company: companyId,
      })

      const conversation = response.body.data
      let newConversation = { ...getters.selectActiveConversation }
      if (newConversation && !newConversation._id) {
        newConversation._id = conversation._id
        commit('SET_CONVERSATIONS', [{ ...newConversation, ...conversation }])
        commit('SET_ACTIVE_CONVERSATION', newConversation)
      }

      return conversation
    },

    async sendReply({ dispatch, getters, rootGetters }, { promoId, message, giftCardAmount }) {
      const { selectActiveConversation } = getters

      const response = await ConversationService.sendReply({
        conversationId: selectActiveConversation._id,
        promoId,
        message,
        giftCardAmount,
      })
      const { messageId, isWithinCelebrationTime: celebrate } = response.body.data.reply
      // Optimistically update timeline with psudeo event
      const { _id, company, customer, location } = selectActiveConversation
      const user = rootGetters['user/onlineUser']
      const loc = rootGetters['location/selectLocation'](location._id)
      const created = new Date().toISOString()
      const analyticEvent = {
        isMessagePlaceholder: true,
        _id: 'temp-' + messageId,
        type: 'outgoing-message',
        company,
        customer,
        location: {
          ...location,
          timezone: loc && loc.timezone,
        },
        conversation: _id,
        message: {
          _id: messageId,
          user: user._id,
          body: message,
          type: 'outgoing',
          created,
          // media: [],
        },
        user,
        created,
        __v: 0,
      }
      dispatch('timelineV2/setTempTimelineEvent', analyticEvent, { root: true })

      if (giftCardAmount) {
        // Optimistically update timeline with psudeo event for gift card
        const { giftCard } = response.body.data
        const giftCardEvent = {
          isMessagePlaceholder: true,
          _id: 'temp-' + giftCard._id,
          type: 'sent-gift-card',
          company,
          customer,
          location: {
            ...location,
            timezone: loc && loc.timezone,
          },
          conversation: _id,
          giftCard,
          user,
          created,
          __v: 0,
        }
        dispatch('timelineV2/setTempTimelineEvent', giftCardEvent, { root: true })
      }
      dispatch('timelineV2/setIsWithinCelebrationTime', celebrate, { root: true })
    },

    async resolveConversation({ dispatch, rootGetters }, conversationId) {
      const response = await ConversationService.markResolved({ conversationId })

      if (response.body.success !== true) {
        throw response.body
      } else {
        await dispatch('conversationChange', {
          conversationData: {
            _id: conversationId,
            resolved: true,
            resolvedBy: rootGetters['dataCore/selectActiveUserId'],
          },
          trace: 'resolveConversation',
        })
      }
    },

    async unresolveConversation({ dispatch, rootGetters }, conversationId) {
      const response = await ConversationService.markUnresolved({ conversationId })

      if (response.body.success !== true) {
        throw response.body
      } else {
        await dispatch('conversationChange', {
          conversationData: {
            _id: conversationId,
            resolved: false,
            resolvedBy: null,
          },
          trace: 'unresolveConversation',
        })
      }
    },

    async conversationChange({ commit, dispatch, getters }, { conversationData, trace }) {
      if (!getters.selectFilters.search) {
        let conversation = getters.selectConversationById(conversationData._id)
        if (conversation) {
          commit('SET_CONVERSATIONS', [{ ...conversation, ...conversationData }])
        } else {
          await dispatch('fetchConversation', {
            conversation: conversationData,
            trace: `conversationChange - ${trace}`,
          })
        }
      }
    },

    async processConversationUpdated({ dispatch, getters }, conversation) {
      const isSearching = getters.selectIsSearching
      if (isSearching) return
      await dispatch('conversationChange', {
        conversationData: conversation,
        trace: 'processConversationUpdated',
      })
    },

    async processConversationAdded({ dispatch, getters }, conversation) {
      const isSearching = getters.selectIsSearching
      if (isSearching) return
      await dispatch('conversationChange', {
        conversationData: conversation,
        trace: 'processConversationAdded',
      })
    },

    async updateIsMobileView({ commit }, status) {
      commit('SET_IS_MOBILE_VIEW', status)
    },

    async updateShowDetailsPane({ commit }, status) {
      commit('SET_SHOW_DETAILS_PANE', status)
    },

    async updateIsWebview({ commit }, status) {
      commit('SET_IS_WEBVIEW', status)
    },

    async updateIsDeeplink({ commit }, status) {
      commit('SET_IS_DEEPLINK', status)
    },

    async saveNote({ getters, rootGetters, dispatch }, { conversationId, text, mentions }) {
      const response = await NoteService.saveNote({ conversationId, text, mentions })
      const { note } = response.body.data
      // Optimistically update timeline with psudeo event
      const { _id, company, customer, location } = getters.selectActiveConversation
      const user = rootGetters['user/onlineUser']
      const loc = rootGetters['location/selectLocation'](location._id)
      const created = new Date().toISOString()
      const analyticEvent = {
        isMessagePlaceholder: true,
        _id: 'temp-' + note._id,
        type: 'internal-note-added',
        company,
        customer,
        location: {
          ...location,
          timezone: loc && loc.timezone,
        },
        conversation: _id,
        note,
        user,
        created,
        __v: 0,
      }
      dispatch('timelineV2/setTempTimelineEvent', analyticEvent, { root: true })
    },

    async generateReply(_, { conversationId }) {
      const response = await ConversationService.generateReply({ conversationId })
      return { ...response.body.data }
    },

    async fetchOpenRates({ commit }, { companyIds, dateRange }) {
      const response = await ConversationService.fetchOpenRates({ companyIds, dateRange })
      commit('SET_OPEN_RATES', response.body.data.data)
    },

    setSearchString({ commit }, searchString) {
      commit('SET_SEARCH_STRING', searchString)
    },
  },
}
