<template>
  <div>
    <!-- normal templates -->
    <div class="card" v-for="template in templates" :key="template._id">
      <div class="card-body">
        <div class="row align-items-center">
          <div class="col">
            <span
              class="h2 mb-2 text-capitalize"
              style="cursor: pointer"
              @click="editTemplate(template)"
              >{{ template.title }}</span
            >
            <br />
            <div class="text-muted mt-2">{{ template.body }}</div>
            <div class="template-promo mt-3" v-if="template.promo || template.promptEachTime">
              <span class="badge text-capitalize badge-soft-primary">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  width="20"
                  height="20"
                  viewBox="0 0 512 512"
                  style="enable-background: new 0 0 512 512; position: relative"
                  xml:space="preserve"
                  :class="[selectedPromo && 'mr-1']"
                >
                  <g>
                    <g>
                      <path
                        fill="currentColor"
                        d="M497.231,211.692c8.157,0,14.769-6.613,14.769-14.769v-78.769c0-8.157-6.613-14.769-14.769-14.769H14.769
                          C6.613,103.385,0,109.997,0,118.154v78.769c0,8.157,6.613,14.769,14.769,14.769c24.431,0,44.308,19.876,44.308,44.308
                          s-19.876,44.308-44.308,44.308C6.613,300.308,0,306.92,0,315.077v78.769c0,8.157,6.613,14.769,14.769,14.769h482.462
                          c8.157,0,14.769-6.613,14.769-14.769v-78.769c0-8.157-6.613-14.769-14.769-14.769c-24.431,0-44.308-19.876-44.308-44.308
                          S472.799,211.692,497.231,211.692z M482.462,328.362v50.715H172.308v-44.308c0-8.157-6.613-14.769-14.769-14.769
                          s-14.769,6.613-14.769,14.769v44.308H29.538v-50.715c33.665-6.862,59.077-36.701,59.077-72.362s-25.412-65.501-59.077-72.362
                          v-50.715h113.231v44.308c0,8.157,6.613,14.769,14.769,14.769s14.769-6.613,14.769-14.769v-44.308h310.154v50.715
                          c-33.665,6.862-59.077,36.701-59.077,72.362S448.797,321.501,482.462,328.362z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        :fill="selectedPromo ? '#fff' : '#283e59'"
                        d="M157.538,221.538c-8.157,0-14.769,6.613-14.769,14.769v39.385c0,8.157,6.613,14.769,14.769,14.769
			                    s14.769-6.613,14.769-14.769v-39.385C172.308,228.151,165.695,221.538,157.538,221.538z"
                      />
                    </g>
                  </g>
                </svg>
                {{ displayPromo(template) }}
              </span>
            </div>
          </div>
          <div class="col-auto">
            <div class="dropdown">
              <a
                href="#"
                class="dropdown-ellipses dropdown-toggle"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="fe fe-more-vertical"></i>
              </a>
              <div class="dropdown-menu dropdown-menu-right">
                <button @click="editTemplate(template)" class="btn btn-link dropdown-item">
                  Edit
                </button>
                <button @click="remove(template._id)" class="btn btn-link dropdown-item">
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- auto templates -->
    <div class="mt-5" v-if="showAutoTemplates">
      <h2>Automated Templates</h2>
      <div class="card" v-for="template in selectAutoTemplates" :key="template._id">
        <div class="card-body">
          <div class="row align-items-center">
            <div class="col">
              <span
                class="h2 mb-2 text-capitalize"
                style="cursor: pointer"
                @click="editAutoTemplate(template)"
              >
                {{ template.title }}
              </span>
              <br />
              <div class="text-muted mt-2">{{ template.body }}</div>
            </div>
            <div class="col-auto">
              <div class="dropdown">
                <a
                  href="#"
                  class="dropdown-ellipses dropdown-toggle"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i class="fe fe-more-vertical"></i>
                </a>
                <div class="dropdown-menu dropdown-menu-right">
                  <a href="#" @click="editAutoTemplate(template)" class="dropdown-item">Edit</a>
                  <a href="#!" @click="confirmDelete(template)" class="dropdown-item">Delete</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TemplateService from '@/services/TemplateService'
import { createNamespacedHelpers } from 'vuex'

const PromoModule = createNamespacedHelpers('promo')
const TemplateModule = createNamespacedHelpers('template')
const CompanyModule = createNamespacedHelpers('company')

export default {
  name: 'TemplateListContent',
  components: {},
  data: () => ({
    selectedPromo: true,
  }),
  computed: {
    ...TemplateModule.mapGetters(['templates', 'selectAutoTemplates']),
    ...PromoModule.mapGetters(['selectPromoName']),
    ...CompanyModule.mapGetters(['selectActiveCompany']),
    showAutoTemplates() {
      return !!(
        this.selectActiveCompany?.settings?.responsesEnabled && this.selectAutoTemplates.length
      )
    },
  },
  methods: {
    ...TemplateModule.mapMutations(['setTemplate']),
    ...TemplateModule.mapActions(['queryTemplates', 'setAutoTemplate', 'deleteAutoTemplate']),
    editTemplate(template) {
      if (template.locations) {
        template.locations = template.locations.filter((location) => location !== null)
      }
      this.setTemplate(template)
      this.$router.push({ name: 'create-template' })
    },
    editAutoTemplate(template) {
      this.setAutoTemplate(template)
      this.$router.push({ name: 'create-template' })
    },
    async remove(templateId) {
      try {
        await TemplateService.remove(templateId)
        this.queryTemplates()
        this.$notify({
          title: 'Success',
          text: `Your template was deleted.`,
        })
      } catch (err) {
        this.$notify({
          title: 'Failed to delete template.',
          text: 'Please contact us for assistance.',
          type: 'error',
        })
      }
    },
    confirmDelete(template) {
      this.$bvModal
        .msgBoxConfirm(
          `Deleting this template will also remove it from any Automated Response settings.`,
          {
            title: 'Are you sure?',
            okVariant: 'danger',
            okTitle: 'Delete',
            titleClass: 'h2 m-0',
            footerClass: 'p-4',
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.removeAutoTemplate(template)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    async removeAutoTemplate(template) {
      try {
        await this.deleteAutoTemplate(template._id)
        this.$notify({
          title: 'Success',
          text: `Your automated template was deleted.`,
        })
      } catch (error) {
        console.log('error', error)
        this.$notify({
          title: 'Failed to delete automated template.',
          text: 'Please contact us for assistance.',
          type: 'error',
        })
      }
    },
    displayPromo(template) {
      if (template.promo && !template.promptEachTime) {
        return this.selectPromoName(template.promo)
      } else {
        return 'Offer Prompted'
      }
    },
  },
}
</script>
