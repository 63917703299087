<template>
  <div class="message" v-if="survey && location">
    <div class="row mt-1 mb-1 message">
      <div class="col-auto mt-0 emoti">
        {{ getRating(survey.rating).emoti }}
      </div>
      <div class="col ml--2">
        <div class="text-left promo comment-body">
          <div class="comment-text" role="alert">
            <span>
              Rated their experience as
              <strong>{{ getRating(survey.rating).title }}.</strong>
            </span>
            <div class="row mt-2 mb--2 d-flex align-items-center justify-content-between">
              <div class="col-auto">
                <p class="comment-time">
                  {{ created | moment('LT') }}
                  {{ timezone }}
                  &#8226;
                  {{ location.friendlyName || location.city }}
                  <span v-if="survey.source">
                    &#8226;
                    {{ survey.source }}
                  </span>
                </p>
              </div>
              <div class="col-auto">
                <button
                  v-if="showDelete"
                  class="btn btn-link btn-sm"
                  @click.prevent="confirmSurveyDelete"
                >
                  <i class="far fa-trash-can text-danger"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getRating } from '@/lib/rating'
import { createNamespacedHelpers } from 'vuex'

const SurveyModule = createNamespacedHelpers('survey')
const ConversationModuleV2 = createNamespacedHelpers('conversationV2')
const CompanyModule = createNamespacedHelpers('company')
const UserModule = createNamespacedHelpers('user')

export default {
  name: 'SurveyEvent',
  props: ['created', 'location', 'survey', 'timezone'],
  computed: {
    ...CompanyModule.mapGetters(['selectActiveCompany']),
    ...UserModule.mapGetters(['selectOnlineUser']),
    showDelete() {
      return (
        this.selectActiveCompany?.settings?.surveyDeleteEnabled &&
        this.selectOnlineUser?.role === 'admin'
      )
    },
  },
  methods: {
    ...SurveyModule.mapActions(['deleteSurvey']),
    ...ConversationModuleV2.mapActions(['refreshActiveConversation']),
    getRating,
    confirmSurveyDelete() {
      this.$bvModal
        .msgBoxConfirm(
          `Are you sure you want to delete this survey? Associated feedback will also be permanently removed.`,
          {
            title: 'Please Confirm!',
            okVariant: 'danger',
            okTitle: 'Delete Survey',
            footerClass: 'p-4',
            centered: true,
          }
        )
        .then(async (value) => {
          if (value) {
            await this.deleteSurvey(this.survey._id)
            await this.refreshActiveConversation()
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
}
</script>

<style lang="scss">
.emoti {
  font-size: 26px;
}
</style>
