<template>
  <div>
    <div class="row">
      <div class="col col-12 col-lg-6">
        <form class="mt-lg-3 card">
          <div class="form-group card-body mb-0">
            <h3 class="mb-1">What would you like to say?</h3>
            <small class="form-text text-muted">
              This is the text message you'll send.
              <a href="#">Need ideas?</a>
            </small>
            <div class="input-group input-group-merge">
              <textarea
                ref="content"
                v-model="form.message"
                style="white-space: pre-line"
                class="form-control sms text-message-orig"
                id="txt"
                maxlength="1000"
                name="content"
                placeholder="Your message here..."
                rows="6"
                @input="sanitizeMessage"
              ></textarea>
            </div>
            <div class="mt-1" v-if="selectMessageLength">
              <p class="small text-muted mb-0">
                {{ selectMessageLength }} Characters,
                <strong class="text-primary">{{ selectSmsCount }}</strong>
                {{ selectSmsCount > 1 ? 'Credits.' : 'Credit.' }}
              </p>
            </div>
            <div class="mt-1">
              <p
                v-if="offendingChars && !mediaSizeIsLarge && !isPictureInCampaign"
                class="alert alert-warning alert-dismissible fade show"
              >
                Your message contains special characters and may consume more credits than usual.
                <a href="https://doc.clickup.com/p/h/87pcq-140/6f0bd0e9186e4d1" target="_blank"
                  >Learn more.</a
                ><br />
                Special characters used: {{ offendingChars }}
              </p>
              <p
                v-if="isPictureInCampaign && !mediaSizeIsLarge && !mediaTypeIsUnsupported"
                class="alert alert-warning alert-dismissible fade show"
              >
                Messages that include an image count as 2 credits and may take a little longer to
                send.
              </p>
              <p
                v-else-if="mediaSizeIsLarge"
                class="alert alert-danger alert-dismissible fade show"
              >
                The image you are trying to upload is too large. Your image should not exceed a size
                of 450 KB. Please try again.
              </p>
              <p
                v-else-if="mediaTypeIsUnsupported"
                class="alert alert-danger alert-dismissible fade show"
              >
                This file type is not supported. Please make sure this file is either a GIF, JPEG,
                or PNG and attempt to re-upload.
              </p>
            </div>
          </div>
          <div class="card-footer">
            <SmartTagsDropdown class="d-inline-block ml-2" @selected="addSmartTag($event)" />
            <MediaDropdown
              class="d-inline-block ml-2"
              v-if="selectActiveCompany.mmsEnabled"
              :data="form.media"
              :loading="isMediaLoading"
              :maxFiles="10"
              @clickAddMedia="clickAddMedia"
              @deleteMedia="deleteMedia"
            />
            <button
              type="button"
              class="btn btn-white btn-sm ml-2 mb-0"
              @click="showSendTestMessageModal"
              :disabled="isSendMessageBtnDisabled"
            >
              Send Test
            </button>
            <SendTestMessageModal ref="sendTestMessageModal" :source="'campaignSetup'" />
          </div>
          <MediaDropzone
            ref="imageDropzone"
            v-if="selectActiveCompany.mmsEnabled && form.media && form.media.length < 10"
            :fileTypes="['image/png', 'image/jpg', 'image/gif', 'image/jpeg']"
            :maxFileSize="512000"
            :maxFiles="10 - form.media.length"
            :maxTotalFileSize="512"
            :currentTotalFileSize="totalSelectedMediaSize"
            @addMedia="addMedia"
            @endLoading="endMediaLoading"
            @startLoading="startMediaLoading"
            @largeFileSize="largeFileSize"
            @resetFileSize="resetFileSize"
            @unsupportedFileType="unsupportedFileType"
            @resetUnsupportedFileType="resetUnsupportedFileType"
          />
        </form>

        <form class="mt-lg-2 mb-lg-0 card">
          <div class="form-group card-body mb-0">
            <h3 class="mb-1">
              Add an Offer
              <span class="text-muted font-weight-normal">(optional)</span>
            </h3>
            <small class="form-text text-muted"> Give them another reason to come back. </small>
            <PromoDropdown
              class="d-inline-block"
              :large="true"
              :includeModal="true"
              :includeUniquePromos="false"
              @selected="addPromo"
              @reset="resetPromo"
              source="campaign"
              parentComponent="campaignSetup"
            />
          </div>
        </form>
      </div>
      <div class="col col-6 justify-content-center text-center">
        <div
          class="d-none d-sm-none d-md-none d-lg-inline-block col-12 text-preview text-center mt--5"
        >
          <MessagePreview class="mt-2" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import emojiRegexRGI from 'emoji-regex/es2015/text.js'
import { evaluateGsmText } from '@/lib/gsm.js'
import { TEMPLATE_CHUNK_TYPES } from '@/lib/modules/campaignSetups'
import MediaDropdown from '@/components/Modules/Media/Dropdown'
import MediaDropzone from '@/components/Modules/Media/Dropzone'
import PromoDropdown from '@/components/Modules/PromoV2/PromoDropdown'
import SmartTagsDropdown from '@/components/Modules/SmartTags/SmartTagsDropdown'
import MessagePreview from './MessagePreview'
import SendTestMessageModal from '@/components/Modals/SendTestMessageModal'
import { createNamespacedHelpers } from 'vuex'
import { sanitizeInput } from '@/lib/validation'

const CampaignSetupsModule = createNamespacedHelpers('campaignSetups')
const CompanyModule = createNamespacedHelpers('company')
const regex = emojiRegexRGI()

export default {
  name: 'CreateMessageForm',

  components: {
    MediaDropdown,
    MediaDropzone,
    MessagePreview,
    PromoDropdown,
    SmartTagsDropdown,
    SendTestMessageModal,
  },
  props: ['active'],
  data: () => ({
    isMediaLoading: false,
    form: {
      media: [],
      message: '',
    },
    selectedMedia: [],
    mediaSizeIsLarge: false,
    mediaTypeIsUnsupported: false,
    offendingChars: '',
  }),
  mounted() {
    if (this.selectCampaignSetup) {
      this.form.message = sanitizeInput(this.selectCampaignSetup.templateChunks[0].template || '')
      this.form.media = Array.prototype.slice.call(
        this.selectCampaignSetup.templateChunks
          .filter((tc) => tc.type === TEMPLATE_CHUNK_TYPES.MEDIA)
          .map((tc) => tc.url) || []
      )
    }
  },
  computed: {
    ...CampaignSetupsModule.mapGetters([
      'selectCampaignSetup',
      'selectMessageLength',
      'selectSmsCount',
    ]),
    ...CompanyModule.mapGetters(['selectActiveCompany']),

    isSendMessageBtnDisabled: function () {
      return this.form.message === null || this.form.message === ''
    },
    isPictureInCampaign() {
      return this.selectCampaignSetup.isPicture || this.form.media.length
    },
    totalSelectedMediaSize() {
      return this.selectedMedia.reduce((acc, media) => acc + media.fileSize, 0)
    },
  },
  watch: {
    'form.message': {
      handler(message) {
        this.checkMessageType()
        if (message.length > 0) {
          this.setMessage(message)
        } else {
          this.setMessage('')
        }
      },
    },
    'form.media': {
      handler() {
        this.checkMessageType()
      },
    },
    active(isActive) {
      if (isActive) {
        this.$refs.content.focus()
      }
    },
  },
  methods: {
    ...CampaignSetupsModule.mapActions(['setCampaignSetup', 'setMessageType']),
    sanitizeMessage(e) {
      this.form.message = sanitizeInput(e.target.value)
    },
    largeFileSize() {
      this.mediaSizeIsLarge = true
    },
    resetFileSize() {
      this.mediaSizeIsLarge = false
    },
    addMedia({ url, fileSize }) {
      if (this.form.media.length < 10) {
        this.form.media.push(url)
        this.selectedMedia.push({ url, fileSize })
        this.setTemplateChunkMedia()
      }
    },
    setTemplateChunkMedia() {
      const media = this.form.media
      this.setCampaignSetup({
        ...this.selectCampaignSetup,
        templateChunks: [
          this.selectCampaignSetup.templateChunks[0],
          ...media.map((m) => ({ type: TEMPLATE_CHUNK_TYPES.MEDIA, url: m })),
        ],
      })
    },
    setMessage(message) {
      // until we support multiple text chunks, the first element will always be the text one
      let templateChunks = [...this.selectCampaignSetup.templateChunks]
      templateChunks[0].template = message
      this.setCampaignSetup({ ...this.selectCampaignSetup, templateChunks })
    },
    addPromo(promo) {
      this.setCampaignSetup({ ...this.selectCampaignSetup, promo: promo._id })
    },
    addSmartTag(tag) {
      this.form.message += ` ${tag.value}`
    },
    clickAddMedia() {
      this.$refs.imageDropzone.$el.getElementsByTagName('input')[0].click()
    },
    deleteMedia(idx) {
      this.form.media.splice(idx, 1)
      this.selectedMedia.splice(idx, 1)
      this.setTemplateChunkMedia()
    },
    endMediaLoading() {
      this.isMediaLoading = false
    },
    resetPromo() {
      const campaign = { ...this.selectCampaignSetup }
      delete campaign.promo
      this.setCampaignSetup(campaign)
    },
    startMediaLoading() {
      this.isMediaLoading = true
    },
    showSendTestMessageModal() {
      this.$root.$emit('bv::show::modal', 'sendTestMessageModal')
    },
    checkMessageType() {
      const formattedText = this.form.message.replace(/[0-9]/g, '').replace(/[*|#]/g, '')
      let match = formattedText.match(regex)
      // check for non gsm
      const textEvaluation = evaluateGsmText(this.form.message)
      match = !textEvaluation.status
      this.offendingChars = textEvaluation.offendingChars
      if (match || this.form.media.length) {
        if (this.form.media.length && !match) {
          this.setMessageType({ MMSType: true, unicodeType: false, isPicture: true })
        } else if (match && !this.form.media.length) {
          this.setMessageType({ MMSType: true, unicodeType: true, isPicture: false })
        } else {
          this.setMessageType({ MMSType: true, unicodeType: true, isPicture: true })
        }
      } else {
        this.setMessageType({ MMSType: false, unicodeType: false, isPicture: false })
      }
    },
    unsupportedFileType() {
      this.mediaTypeIsUnsupported = true
    },
    resetUnsupportedFileType() {
      this.mediaTypeIsUnsupported = false
    },
  },
}
</script>
