import { render, staticRenderFns } from "./MessageCompose.vue?vue&type=template&id=02d5ae98&scoped=true"
import script from "./MessageCompose.vue?vue&type=script&lang=js"
export * from "./MessageCompose.vue?vue&type=script&lang=js"
import style0 from "./MessageCompose.vue?vue&type=style&index=0&id=02d5ae98&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02d5ae98",
  null
  
)

export default component.exports