<template>
  <b-modal ref="modal" id="addIntegrationModal" hide-footer hide-header body-class="p-0">
    <div class="modal-card card">
      <div class="card-header">
        <div class="row align-items-center">
          <div class="col">
            <h4 class="card-header-title" id="exampleModalCenterTitle">
              {{ isConnectedModel ? 'Update' : 'Connect' }}
              {{ integration.displayName || integration.name }}
            </h4>
          </div>
          <div class="col-auto">
            <button @click="close" type="button" class="close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
        </div>
      </div>
      <div class="card-body" style="max-height: 650px">
        <div class="row">
          <div class="col-12 mb-4" v-if="!loadingIntegration && !needsOauth">
            <div v-if="integration.isOrg" class="form-group d-flex">
              <label class="">{{ integration.isOrg.label }}</label>
              <div class="custom-control custom-checkbox d-flex ml-3">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  :id="integration.isOrg.name"
                  v-model="credentials.isOrg"
                />
                <label class="custom-control-label" :for="integration.isOrg.name"></label>
              </div>
            </div>
            <div class="form-group" v-for="input in integration.clipboards" :key="input.name">
              <label :for="input.name" class="mb-1">{{ input.label }}</label>
              <div class="input-group input-group-merge">
                <input
                  readonly
                  type="text"
                  :id="input.name"
                  :ref="input.name"
                  :placeholder="input.label"
                  :value="
                    credentials.isOrg
                      ? input.urlWithOrgId(orgId)
                      : input.urlWithCompanyId(companyId)
                  "
                  class="form-control form-control-appended bg-light"
                />
                <div class="input-group-append">
                  <div class="input-group-text p-0 bg-light">
                    <button
                      class="btn btn-light border-0"
                      aria-label="copy to clipboard"
                      v-clipboard:copy="
                        credentials.isOrg
                          ? input.urlWithOrgId(orgId)
                          : input.urlWithCompanyId(companyId)
                      "
                      v-clipboard:success="onCopyToClipboard"
                    >
                      <span class="fe fe-clipboard"></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group" v-for="output in integrationOutputs" :key="output.name">
              <label :for="output.name" class="mb-1">{{ output.label }}</label>
              <div class="input-group input-group-merge" v-if="output.type === 'clipboard'">
                <input
                  readonly
                  type="text"
                  :id="output.name"
                  :ref="output.name"
                  :placeholder="output.label"
                  :value="getOutputValue(output)"
                  class="form-control form-control-appended bg-light"
                />
                <div class="input-group-append">
                  <div class="input-group-text p-0 bg-light">
                    <button
                      class="btn btn-light border-0"
                      aria-label="copy to clipboard"
                      v-clipboard:copy="getOutputValue(output)"
                      v-clipboard:success="onCopyToClipboard"
                    >
                      <span class="fe fe-clipboard"></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group" v-if="!needsOauth && integration.name === 'square'">
              <div class="row">
                <div class="col-md-6">
                  <input
                    type="text"
                    class="form-control"
                    v-model="oauthInputName"
                    placeholder="Enter OAuth Account Name"
                  />
                </div>
                <div class="col-md-6">
                  <button
                    class="btn btn-success w-100"
                    @click="handleAddOauthAccount"
                    :disabled="!oauthInputName"
                  >
                    Add New Account
                  </button>
                </div>
              </div>
            </div>

            <div
              class="form-group"
              v-if="
                this.needsOauth === false &&
                this.integration.name === 'square' &&
                oAuthAccounts.length > 0
              "
            >
              <label for="oauth-select" class="mb-1">{{
                `${integration.displayName} Accounts`
              }}</label>
              <select
                name="oauth-select"
                class="form-control"
                @input="selectOauth($event.target.value)"
              >
                <option></option>
                <option
                  v-for="oAuthAccount of oAuthAccounts"
                  :key="oAuthAccount.name"
                  :value="oAuthAccount.name == 'default' ? null : oAuthAccount.name"
                >
                  {{ oAuthAccount.name }}
                </option>
              </select>
            </div>

            <div
              class="form-group"
              v-if="connectedIntegration && integration && showLocationSelect"
            >
              <label for="location-select" class="mb-1">{{
                `${integration.displayName} Location`
              }}</label>
              <select
                name="location-select"
                class="form-control"
                :value="connectedIntegrationLocation ? connectedIntegrationLocation.id : undefined"
                @input="selectLocation($event.target.value)"
              >
                <option></option>
                <option
                  v-for="locationOption of externalLocations"
                  :key="locationOption._key"
                  :value="locationOption._key"
                >
                  {{ locationOption.optionName }}
                </option>
              </select>
            </div>
            <div class="form-group" v-for="input in integrationInputs" :key="input.name">
              <label :for="input.name" class="mb-1">{{ input.label }}</label>
              <!-- this seems wrong to use updateCredentials here instead of updateField -->
              <!-- but I'm afraid of breaking other stuff... :/ -->
              <input
                :id="input.name"
                :ref="input.name"
                :value="getInputValue(input)"
                @input="updateCredentials(input.name, $event.target.value)"
                :placeholder="input.label"
                class="form-control"
                :type="input.type"
              />
            </div>
            <div class="form-group" v-for="input in oldIntegrationInputs" :key="input.name">
              <label :for="input.name" class="mb-1">{{ input.label }}</label>
              <input
                :id="input.name"
                :ref="input.name"
                :value="getCredentialValue(input.name)"
                @input="updateCredentials(input.name, $event.target.value)"
                :placeholder="input.label"
                class="form-control"
                :type="input.type"
              />
            </div>
            <div class="form-group" v-for="input in checkboxInputs" :key="input.name">
              <label class="mb-1">{{ input.label }}</label>
              <div class="row">
                <div class="col-auto">
                  <!-- Switch -->
                  <div class="custom-control custom-checkbox-toggle">
                    <input
                      class="custom-control-input"
                      :id="input.name"
                      :ref="input.name"
                      @change="updateField(input.name, $event.target.checked)"
                      :checked="getInputValue(input)"
                      type="checkbox"
                    />
                    <label class="custom-control-label" :for="input.name"></label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12" v-if="connectedIntegration && needsOauth">
            <button
              class="btn col-12"
              style="background-color: #ff4450"
              @click="openOauthAuthorizationLink()"
            >
              {{ `Authorize Ovation via ${integration.displayName}` }}
            </button>
          </div>
          <div class="col-12" v-if="!needsOauth">
            <button
              @click="isConnectedModel ? update() : create()"
              :disabled="isLoading || !isFormValid || addingIntegration || loadingIntegration"
              class="btn btn-primary col-12"
              :class="{ 'is-loading': isLoading || addingIntegration || loadingIntegration }"
            >
              {{ isConnectedModel ? 'Update' : 'Connect' }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex'
import Config from '@/config'
const IntegrationModule = createNamespacedHelpers('integration')
const CompanyModule = createNamespacedHelpers('company')

export default {
  name: 'AddIntegrationModal',
  props: ['location'],
  components: {},
  data: () => ({
    dynamicFields: {},
    isLoading: false,
    integration: {},
    credentials: {},
    connectedIntegration: null,
    connectedIntegrationLocation: null,
    externalLocations: [],
    showLocationSelect: false,
    oAuthName: '',
    oauthInputName: '',
  }),
  computed: {
    ...mapGetters(['selectIsLoading']),
    ...CompanyModule.mapGetters(['activeCompany']),
    needsOauth() {
      return (
        this.connectedIntegration &&
        this.connectedIntegration.oauth &&
        !this.connectedIntegration.oauth.authorized
      )
    },
    integrationOutputs() {
      return this.integration ? this.integration.outputs : []
    },
    checkboxInputs() {
      return this.integration?.inputs?.filter(({ type }) => type === 'checkbox')
    },
    /**
     * @deprecated
     */
    integrationInputs() {
      return this.connectedIntegration && this.connectedIntegration.integrationProvider
        ? this.integration.inputs?.filter(({ type }) => type !== 'checkbox')
        : []
    },
    /**
     * @deprecated
     */
    oldIntegrationInputs() {
      return this.connectedIntegration && this.connectedIntegration.integrationProvider
        ? []
        : this.integration.inputs?.filter(({ type }) => type !== 'checkbox')
    },
    addingIntegration() {
      return this.selectIsLoading([
        `ADD_INTEGRATION_${
          this.integration && this.integration.type ? this.integration.type : null
        }`,
      ])
    },
    loadingIntegration() {
      return this.selectIsLoading([`GET_INTEGRATION_${this.connectedIntegration?._id}`])
    },
    orgId() {
      return 'company' in this.location ? this.location.company.organization : ''
    },
    companyId() {
      return 'company' in this.location ? this.location.company._id : ''
    },
    oAuthAccounts() {
      let accountsArray = []
      if (
        this.location.company.integrationDetails.square &&
        this.location.company.integrationDetails.square.oauthAccounts &&
        this.location.company.integrationDetails.square.oauthAccounts.length > 0
      ) {
        accountsArray = [
          { name: 'default', oauth: this.location.company.integrationDetails.square.oauth },
          ...this.location.company.integrationDetails.square.oauthAccounts,
        ]
      }
      return accountsArray
    },
    isFormValid() {
      return this.integration
        ? (this.integration.inputs || []).every(
            ({ name }) => name in this.credentials || name in this.dynamicFields
          )
        : false
    },
    isConnectedModel() {
      return this.connectedIntegration && !this.connectedIntegration?.disabled
    },
    hasIntegrationProvider() {
      return !!this.connectedIntegration?.integrationProvider
    },
  },
  watch: {
    connectedIntegration(val, oldVal) {
      if (!oldVal && val && val.integrationProvider) {
        this.getIntegrationAndSetInputs(val._id)
      }
    },
    integration(newIntegration) {
      if (newIntegration) {
        this.setLocationSelectOptions()
      }

      if ('clipboards' in newIntegration) {
        this.integration.clipboards.forEach(
          ({ name, urlWithOrgId = () => {}, urlWithCompanyId }) => {
            const value = this.credentials.isOrg
              ? urlWithOrgId(this.orgId)
              : urlWithCompanyId(this.companyId)
            this.updateCredentials(name, value)
          }
        )
      }
    },
    'credentials.isOrg'() {
      if ('clipboards' in this.integration) {
        this.integration.clipboards.forEach(({ name, urlWithOrgId, urlWithCompanyId }) => {
          const value = this.credentials.isOrg
            ? urlWithOrgId(this.orgId)
            : urlWithCompanyId(this.companyId)
          this.updateCredentials(name, value)
        })
      }
    },
  },
  methods: {
    ...IntegrationModule.mapActions([
      'addIntegration',
      'updateIntegration',
      'getIntegration',
      'getChowNowLocations',
      'getSquareLocations',
      'getQuLocations',
    ]),
    openOauthAuthorizationLink() {
      window.parent.postMessage(
        { openUrl: this.connectedIntegration.oauth.authorizationLink },
        Config.baseAppUrl
      )
    },
    getOutputValue(output) {
      if (output.value) {
        return output.value
          .replace('${companyId}', this.location.company._id)
          .replace('${locationId}', this.location._id)
      } else if (output.path) {
        let path = output.path.split('.')

        let val = this
        for (var key of path) {
          val = val[key] || ''
        }

        return val
      }
    },
    selectOauth(value) {
      this.oAuthName = value
      this.setLocationSelectOptions()
    },
    handleAddOauthAccount() {
      localStorage.setItem('oauthInputName', this.oauthInputName)
      this.openOauthAuthorizationLink()
    },
    selectLocation(value) {
      this.updateCredentials('externalLocationId', value)

      if (value) {
        const externalLocation = this.externalLocations.find((el) => el._key === value)
        let externalCompanyId = null
        switch (this.integration.name) {
          case 'square':
            externalCompanyId = externalLocation.merchant_id
            break
        }

        if (externalCompanyId) {
          this.updateCredentials('externalCompanyId', externalCompanyId)
        }
      }
    },
    getInputValue(input) {
      let path = input.path.split('.')

      let val = this
      for (var key of path) {
        if (!val) break
        val = val[key]
      }

      return val
    },
    async setLocationSelectOptions() {
      let locations = []
      this.showLocationSelect = false
      if (!this.needsOauth) {
        try {
          switch (this.integration.name) {
            case 'chownow-api':
              try {
                locations = await this.getChowNowLocations()
              } catch (error) {
                locations = []
              }
              locations = locations.map((location) => {
                location._key = location.restaurant_id
                location.optionName = `${location.name} - ${location.restaurant_id}`
                return location
              })
              this.showLocationSelect = true
              break

            case 'square':
              try {
                locations = await this.getSquareLocations(this.oAuthName)
              } catch (error) {
                locations = []
              }
              locations = locations.map((location) => {
                location._key = location.id
                location.optionName = `${location.name} - ${location.id}`
                return location
              })
              this.showLocationSelect = true
              break

            case 'qu':
              locations = await this.getQuLocations()
              locations = locations.map((location) => {
                location._key = location.id
                location.optionName = `${location.dba_name} - ${location.id}`
                return location
              })
              this.showLocationSelect = true
              break
          }
        } catch (e) {
          this.$notify({
            type: 'error',
            text: `Something went wrong authenticating with ${this.integration.name}`,
            duration: 5000,
          })
          this.close()
        }
      }

      this.externalLocations = locations
    },
    async getIntegrationAndSetInputs(integrationId) {
      const result = await this.getIntegration(integrationId)
      this.connectedIntegration = result.data.data.integration
      this.connectedIntegrationLocation = result.data.data.integrationLocation
      if (this.integration.inputs) {
        for (var input of this.integration.inputs) {
          const path = input.path.split('.')
          let value = this
          for (var key of path) {
            value = value[key]
          }
          if (typeof value !== 'boolean') {
            this.updateCredentials(input.name, value)
          }
          this.updateField(input.name, value)
        }
      }
    },
    setIntegration(integration) {
      this.integration = integration
      if (integration.inputs && integration.inputs.length) {
        integration.inputs.forEach((input) => {
          this.updateField(input.name)
        })
      }
    },
    setConnectedIntegration(connectedIntegrations) {
      this.connectedIntegration = connectedIntegrations
      if (connectedIntegrations && connectedIntegrations.credentials) {
        this.$set(this.credentials, 'isOrg', connectedIntegrations.credentials.isOrg)
      }

      if (this.connectedIntegration && this.connectedIntegration.integrationProvider) {
        this.getIntegrationAndSetInputs(this.connectedIntegration._id)
      }
    },
    getCredentialValue(name) {
      let value = null
      if (this.connectedIntegration && !this.connectedIntegration.integrationProvider) {
        value = this.connectedIntegration.credentials[name]
        if (value) {
          this.updateCredentials(name, value)
        }
      } else if (!this.isLoading) {
        // While update/create API is in progress, app tries to get older data from
        // connectedIntegration object. Avoid doing that by fetching new data from credentials.
        value = this.credentials[name]
      }
      return value
    },
    updateField(key, value) {
      this.$set(this.dynamicFields, key, value)
    },
    updateCredentials(key, value = '') {
      if (key === 'locationName') {
        this.$set(this.credentials, 'externalLocationName', value.trim())
      }
      this.$set(this.credentials, key, value.trim())
    },
    async create() {
      // ...or create
      const addIntegrationResult = await this.addIntegration({
        type: this.integration.type,
        credentials: this.credentials,
        provider: this.integration.provider,
        locationId: this.location._id,
        companyId: this.location.company._id,
        integrationProviderId: this.integration._id,
        ...this.dynamicFields,
      })

      if (addIntegrationResult.success === true) {
        this.$notify({
          title: 'Connected',
          text: `${this.integration.name} was added.`,
        })

        if (this.integration.outputs && this.integration.outputs.length) {
          this.getIntegrationAndSetInputs(addIntegrationResult.data.data.data.integrationId)
        } else {
          this.close()
        }

        this.$emit('connected', this.integration.provider)
      } else {
        this.$notify({
          title: addIntegrationResult.data,
          type: 'error',
        })
      }
    },
    async update() {
      this.isLoading = true
      const payload = {
        fields: {
          company: this.location.company._id,
          location: this.location._id,
          // TODO verify if "Location" field name is correct
          Location: this.location._id,
          credentials: this.credentials,
          provider: this.integration.provider,
          integrationProvider: this.integration._id,
          type: this.integration.type,
          ...this.dynamicFields,
          oAuthAccount: this.oAuthName,
        },
        integrationId: this.connectedIntegration._id,
      }
      // this.credentials has the updated fields of the form where fields has the original
      const updatePayload = {
        ...payload,
        updatedFields: this.credentials,
      }
      const response = await this.updateIntegration(updatePayload)

      if (response && response.status === 200) {
        this.$notify({
          title: 'Updated',
          text: `${this.integration.name} was updated.`,
        })
      } else {
        this.$notify({
          title: 'Error',
          type: 'error',
          text: `We are unable to update ${this.integration.name}.`,
        })
      }

      this.close()
      this.$root.$emit('getLocationData')
      this.isLoading = false
    },
    close() {
      this.credentials = {}
      this.connectedIntegrationLocation = null
      this.isLoading = false
      this.$refs.modal.hide()
    },
    onCopyToClipboard() {
      this.$notify({
        title: 'Copied to clipboard!',
      })
    },
  },
}
</script>
