<template>
  <multiselect
    :style="{
      width: (filteredLocations && filteredLocations.length) || wideMode ? 'auto' : '140px',
    }"
    @input="updateLocations"
    :limit="2"
    v-model="filteredLocations"
    :options="options"
    track-by="_id"
    :multiple="true"
    group-label="divider"
    selectLabel=""
    placeholder="Type to search"
    group-values="data"
    :group-select="true"
    :custom-label="customLabelName"
    :showNoResults="true"
    :close-on-select="false"
    selectGroupLabel="Select All"
    deselectGroupLabel="Deselect All"
    :limitText="(count) => `+${count} more`"
  >
    <span slot="noResult">No results.</span>
    <template slot="placeholder">
      <span class="text-secondary">
        <span class="fe fe-map-pin"></span> {{ defaultText || 'Select locations' }}</span
      >
    </template>
    <template slot="option" slot-scope="props">
      <div>
        <span v-if="props.option.$isLabel">{{ props.option.$groupLabel }}</span>
        <span v-else>{{
          props.option.friendlyName || props.option.city || props.option.name
        }}</span>
        <br />
        <small class="text-muted small text-truncate" v-if="props.option.addressDetails">{{
          `${props.option.addressDetails.streetNumber} ${props.option.addressDetails.streetName}`
            | truncate(20)
        }}</small>
      </div>
    </template>
  </multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { createNamespacedHelpers, mapGetters, mapActions } from 'vuex'
const CompanyModule = createNamespacedHelpers('company')
const TemplateModule = createNamespacedHelpers('template')
const UserModule = createNamespacedHelpers('user')
const CampaignModule = createNamespacedHelpers('campaign')

export default {
  name: 'CustomerLocationFilter',
  props: ['wideMode', 'filteredCompanies', 'defaultText', 'forTeam'],
  components: {
    Multiselect,
  },
  data: () => ({
    filteredLocations: undefined,
    locations: [],
    allLocations: [],
    options: [],
  }),
  computed: {
    ...mapGetters('location', ['userActiveCompanyLocations']),
    ...UserModule.mapState(['onlineUser']),
    ...CompanyModule.mapState(['activeCompany']),
    ...CompanyModule.mapGetters(['selectEmployees']),
    ...TemplateModule.mapState(['template']),
    ...CampaignModule.mapState(['campaign']),
  },
  methods: {
    ...mapActions('location', ['getUserLocationsByActiveCompany']),

    customLabelName(location) {
      return location.friendlyName || location.city || location.name
    },
    reset(locations = []) {
      this.filteredLocations = [...locations]
    },
    updateLocations(selectedLocations) {
      let finalLocations = []
      selectedLocations.forEach((location) => {
        const { locationIds } = location

        if (locationIds && locationIds.length) {
          locationIds.forEach((item) => {
            const found = finalLocations.find((obj) => obj._id === item._id)
            // ignore duplicates
            if (!found) {
              finalLocations.push(item)
            }
          })
        } else {
          // check if the locations are already included
          const found = finalLocations.find((item) => item._id === location._id)
          // ignore duplicates
          if (!found) {
            finalLocations.push(location)
          }
        }
      })

      this.$emit('updateLocations', finalLocations)
    },
    setLocations() {
      let self = this
      this.locations = [...this.userActiveCompanyLocations].filter(
        (location) => !self.filteredCompanies || self.filteredCompanies.includes(location.company)
      )
      this.allLocations = this.locations
    },
  },
  watch: {
    userActiveCompanyLocations: {
      deep: true,
      handler(val) {
        this.setLocations()
      },
    },
    filteredCompanies() {
      this.setLocations()
    },
  },
  async mounted() {
    await this.getUserLocationsByActiveCompany()
    this.setLocations()
    if (
      this.campaign &&
      this.campaign._id &&
      this.campaign.filters.locations &&
      this.campaign.filters.locations.length
    ) {
      this.filteredLocations = this.campaign.filters.locations
        .map((locationId) =>
          this.userActiveCompanyLocations.find((location) => location._id === locationId)
        )
        .filter((location) => location)
      this.updateLocations(this.filteredLocations)
    } else if (!this.forTeam) {
      this.filteredLocations = this.allLocations.filter((location) =>
        this.template?.locations?.includes(location._id)
      )
    }

    if (this.allLocations && this.allLocations.length) {
      this.options.push({
        divider: 'Locations',
        data: this.allLocations,
      })
    }
  },
}
</script>
<style>
.multiselect__option--group {
  background: #2c7be5 !important;
  color: white !important;
  font-size: 14px !important;
}
</style>
